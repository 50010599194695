import { IPermissionData } from '@/api/types'
import BooleanUtil from '@/utils/boolean'

/**
 * @Author: siyukok
 * @Date: 2022/4/13 11:37
 * @Version 1.0
 */
export interface IPermissionConst {
  AbpIdentity: {
    groupName: string
    Roles: {
      Group: string
      Create: string
      Update: string
      Delete: string
      ManagePermissions: string
    }
    Users: {
      Group: string
      Create: string
      Update: string
      Delete: string
      ManagePermissions: string
    }
  }
  DpBackend: {
    groupName: string
    Statistics: {
      Group: string
      Info: string
      OceanEngine: string
    }
    Clues: {
      Group: string
      Create: string
      Edit: string
      Get: string
      List: string
      ListMe: string
      ListWait: string
      ListMeCreated: string
      Follow: string
      OrderSucceed: string
      OrderFail: string
      Return: string
      Invalid: string
      Allocated: string
      ListCustomer: string
    }
    Customers: {
      Group: string
      Get: string
      List: string
      Search: string
      EditName: string
      EditGender: string
      EditRemark: string
      EditContactWay: string
      AddContactWay: string
    }
    CustomerIdentitys: {
      Group: string
      Create: string
      Edit: string
      Get: string
      List: string
      Enable: string
    }
    Dicts: {
      Group: string
      List: string
      Create: string
      CreateDict: string
    }
    Orders: {
      Group: string
      Create: string
      Get: string
      List: string
      Search: string
      ListCustomer: string
      PayRecord: string
      CreatePaymentRecord: string
      CreateRefundRecord: string
      Cancel: string
      Review: string
      RePush: string
    }
    Products: {
      Group: string
      Search: string
      List: string
      Get: string
      Add: string
      Edit: string
      Del: string
      Using: string
      Cancel: string

    }
    Tags: {
      Group: string
      EditTag: string
      DelTag: string
      SetTag: string
      GetTagGroup: string
      List: string
      ListAll: string
      CreateTagGroup: string
      EditTagGroup: string
      DelTagGroup: string
      GetTag: string
      CreateTag: string
      ListReadOnlyAll: string
    }
    CorpUsers: {
      Group: string
      List: string
      Search: string
      Refresh: string
      Info: string
      Add: string
      Edit: string
      Del: string
      Get: string

    }
    WeChatCustomers: {
      Group: string
      Info: string
      List: string
    }
    Departments: {
      Group: string
      List: string
      Refresh: string
      Add: string
      Edit: string
      Del: string
      Leader: string
    }
    Channels: {
      Group: string
      List: string
      Get: string
      Add: string
      Edit: string
      Del: string
      Sign: string
      SelectItemSignedList: string
    }
    Activities: {
      Group: string
      List: string
      Get: string
      Add: string
      Edit: string
      Del: string
      Confirm: string
    }
    SmartContracts: {
      Group: string
      List: string
      Get: string
      Add: string
      Edit: string
      SelectItems: string
    }
    WebCalls: {
      Group: string
      List: string
      ListMe: string
      Call: string
    }
    Questions: {
      Group: string
      List: string
      Get: string
      Add: string
      Edit: string
      Del: string
      Using: string
      Cancel: string
      ListUsing: string
    }
    Evaluations: {
      Group: string
      List: string
      ListAll: string
      Get: string
      Add: string
      Edit: string
      Del: string
      Using: string
      Cancel: string
      Dimensions: string
      AddDimension: string
      EditDimension: string
      DelDimension: string
      DimensionScoreRanges: string
      AddDimensionScoreRange: string
      EvaluationQuestion: string
      AddEvaluationQuestion: string
      EvaluationQuestionScore: string
      EvaluationCustomers: string
      PdfFile: string
    }
    Configs: {
      GetWeCom: string
      GetOceanengine: string
      GetWebCall: string
      GetWeChatApplet: string
      SetWeCom: string
      SetOceanengine: string
      SetWebCall: string
      SetWeChatApplet: string
      CheckWeCom: string
      CheckOceanengine: string
      CheckWebCall: string
      CheckWeChatApplet: string
    }
  }
}

export default class PermissionConst {
  static Group = {
    AbpIdentity: {
      groupName: 'AbpIdentity',
      Roles: {
        Group: 'AbpIdentity.Roles',
        Create: 'AbpIdentity.Roles.Create',
        Update: 'AbpIdentity.Roles.Update',
        Delete: 'AbpIdentity.Roles.Delete',
        ManagePermissions: 'AbpIdentity.Roles.ManagePermissions'
      },
      Users: {
        Group: 'AbpIdentity.Users',
        Create: 'AbpIdentity.Users.Create',
        Update: 'AbpIdentity.Users.Update',
        Delete: 'AbpIdentity.Users.Delete',
        ManagePermissions: 'AbpIdentity.Users.ManagePermissions'
      }
    },
    DpBackend: {
      groupName: 'DpBackend',
      Statistics: {
        Group: 'DpBackend.Statistics',
        Info: 'DpBackend.Statistics.Info',
        OceanEngine: 'DpBackend.Statistics.Oceanengine'
      },
      Clues: {
        Group: 'DpBackend.Clues',
        Create: 'DpBackend.Clues.Create',
        Edit: 'DpBackend.Clues.Edit',
        Get: 'DpBackend.Clues.Get',
        List: 'DpBackend.Clues.List',
        ListMe: 'DpBackend.Clues.ListMe',
        ListWait: 'DpBackend.Clues.ListWait',
        ListMeCreated: 'DpBackend.Clues.ListMeCreated',
        Follow: 'DpBackend.Clues.Follow',
        OrderSucceed: 'DpBackend.Clues.OrderSucceed',
        OrderFail: 'DpBackend.Clues.OrderFail',
        Return: 'DpBackend.Clues.Return',
        Invalid: 'DpBackend.Clues.Invalid',
        Allocated: 'DpBackend.Clues.Allocated',
        ListCustomer: 'Permission:Clues.ListCustomer'
      },
      Customers: {
        Group: 'DpBackend.Customers',
        Get: 'DpBackend.Customers.Get',
        List: 'DpBackend.Customers.List',
        Search: 'DpBackend.Customers.Search',
        EditName: 'DpBackend.Customers.EditName',
        EditGender: 'DpBackend.Customers.EditGender',
        EditRemark: 'DpBackend.Customers.EditRemark',
        EditContactWay: 'DpBackend.Customers.EditContactWay',
        AddContactWay: 'DpBackend.Customers.AddContactWay',
        Merge: 'DpBackend.Customers.Merge'
      },
      CustomerIdentitys: {
        Group: 'DpBackend.CustomerIdentitys',
        Create: 'DpBackend.CustomerIdentitys.Create',
        Edit: 'DpBackend.CustomerIdentitys.Edit',
        Get: 'DpBackend.CustomerIdentitys.Get',
        List: 'DpBackend.CustomerIdentitys.List',
        Enable: 'DpBackend.CustomerIdentitys.Enable'
      },
      Dicts: {
        Group: 'DpBackend.Dicts',
        List: 'DpBackend.Dicts.List',
        Create: 'DpBackend.Dicts.Create',
        CreateDict: 'DpBackend.Dicts.CreateDict'
      },
      Orders: {
        Group: 'DpBackend.Orders',
        Create: 'DpBackend.Orders.Create',
        Get: 'DpBackend.Orders.Get',
        List: 'DpBackend.Orders.List',
        Search: 'DpBackend.Orders.Search',
        ListCustomer: 'DpBackend.Orders.ListCustomer',
        PayRecord: 'DpBackend.Orders.PayRecord',
        CreatePaymentRecord: 'DpBackend.Orders.CreatePaymentRecord',
        CreateRefundRecord: 'DpBackend.Orders.CreateRefundRecord',
        Cancel: 'DpBackend.Orders.Cancel',
        Review: 'DpBackend.Orders.Review',
        RePush: 'DpBackend.Orders.RePush'
      },
      Products: {
        Group: 'DpBackend.Products',
        Search: 'DpBackend.Products.Search'
      },
      Tags: {
        Group: 'DpBackend.Tags',
        EditTag: 'DpBackend.Tags.EditTag',
        DelTag: 'DpBackend.Tags.DelTag',
        SetTag: 'DpBackend.Tags.SetTag',
        GetTagGroup: 'DpBackend.Tags.GetTagGroup',
        List: 'DpBackend.Tags.List',
        ListAll: 'DpBackend.Tags.ListAll',
        CreateTagGroup: 'DpBackend.Tags.CreateTagGroup',
        EditTagGroup: 'DpBackend.Tags.EditTagGroup',
        DelTagGroup: 'DpBackend.Tags.DelTagGroup',
        GetTag: 'DpBackend.Tags.GetTag',
        CreateTag: 'DpBackend.Tags.CreateTag',
        ListReadOnlyAll: 'DpBackend.Tags.ListReadOnlyAll'
      },
      CorpUsers: {
        Group: 'DpBackend.CorpUsers',
        List: 'DpBackend.CorpUsers.List',
        Search: 'DpBackend.CorpUsers.Search',
        Refresh: 'DpBackend.CorpUsers.Refresh',
        Info: 'DpBackend.CorpUsers.Info',
        Add: 'DpBackend.CorpUsers.Add',
        Edit: 'DpBackend.CorpUsers.Edit',
        Del: 'DpBackend.CorpUsers.Del',
        Get: 'DpBackend.CorpUsers.Get'
      },
      WeChatCustomers: {
        Group: 'DpBackend.WeChatCustomers',
        Info: 'DpBackend.WeChatCustomers.Info',
        List: 'DpBackend.WeChatCustomers.List'
      },
      Departments: {
        Group: 'DpBackend.Departments',
        List: 'DpBackend.Departments.List',
        Refresh: 'DpBackend.Departments.Refresh',
        Add: 'DpBackend.Departments.Add',
        Edit: 'DpBackend.Departments.Edit',
        Del: 'DpBackend.Departments.Del',
        Leader: 'DpBackend.Departments.Leader'
      },
      Channels: {
        Group: 'DpBackend.Channels',
        List: 'DpBackend.Channels.List',
        Get: 'DpBackend.Channels.Get',
        Add: 'DpBackend.Channels.Add',
        Edit: 'DpBackend.Channels.Edit',
        Del: 'DpBackend.Channels.Del',
        Sign: 'DpBackend.Channels.Sign',
        SelectItemSignedList: 'DpBackend.Channels.SelectItemSignedList'
      },
      Activities: {
        Group: 'DpBackend.Activities',
        List: 'DpBackend.Activities.List',
        Get: 'DpBackend.Activities.Get',
        Add: 'DpBackend.Activities.Add',
        Edit: 'DpBackend.Activities.Edit',
        Del: 'DpBackend.Activities.Del',
        Confirm: 'DpBackend.Activities.Confirm'
      },
      SmartContracts: {
        Group: 'DpBackend.SmartContracts',
        List: 'DpBackend.SmartContracts.List',
        Get: 'DpBackend.SmartContracts.Get',
        Add: 'DpBackend.SmartContracts.Add',
        Edit: 'DpBackend.SmartContracts.Edit',
        SelectItems: 'DpBackend.SmartContracts.SelectItems'
      },
      WebCalls: {
        Group: 'DpBackend.WebCalls',
        List: 'DpBackend.WebCalls.List',
        ListMe: 'DpBackend.WebCalls.ListMe',
        Call: 'DpBackend.WebCalls.Call'
      },
      Questions: {
        Group: 'DpBackend.Questions',
        List: 'DpBackend.Questions.List',
        Get: 'DpBackend.Questions.Get',
        Add: 'DpBackend.Questions.Add',
        Edit: 'DpBackend.Questions.Edit',
        Del: 'DpBackend.Questions.Del',
        Using: 'DpBackend.Questions.Using',
        Cancel: 'DpBackend.Questions.Cancel',
        ListUsing: 'DpBackend.Questions.ListUsing'
      },
      Evaluations: {
        Group: 'DpBackend.Evaluations',
        List: 'DpBackend.Evaluations.List',
        ListAll: 'DpBackend.Evaluations.ListAll',
        Get: 'DpBackend.Evaluations.Get',
        Add: 'DpBackend.Evaluations.Add',
        Edit: 'DpBackend.Evaluations.Edit',
        Del: 'DpBackend.Evaluations.Del',
        Using: 'DpBackend.Evaluations.Using',
        Cancel: 'DpBackend.Evaluations.Cancel',
        Dimensions: 'DpBackend.Evaluations.Dimensions',
        AddDimension: 'DpBackend.Evaluations.AddDimension',
        EditDimension: 'DpBackend.Evaluations.EditDimension',
        DelDimension: 'DpBackend.Evaluations.DelDimension',
        DimensionScoreRanges: 'DpBackend.Evaluations.DimensionScoreRanges',
        AddDimensionScoreRange: 'DpBackend.Evaluations.AddDimensionScoreRange',
        EvaluationQuestion: 'DpBackend.Evaluations.EvaluationQuestion',
        AddEvaluationQuestion: 'DpBackend.Evaluations.AddEvaluationQuestion',
        EvaluationQuestionScore: 'DpBackend.Evaluations.EvaluationQuestionScore',
        EvaluationCustomers: 'DpBackend.Evaluations.EvaluationCustomers',
        PdfFile: 'DpBackend.Evaluations.PdfFile'
      },
      Configs: {
        Group: 'DpBackend.Configs',
        GetWeCom: 'DpBackend.Configs.GetWeCom',
        GetOceanengine: 'DpBackend.Configs.GetOceanengine',
        GetWebCall: 'DpBackend.Configs.GetWebCall',
        GetWeChatApplet: 'DpBackend.Configs.GetWeChatApplet',
        SetWeCom: 'DpBackend.Configs.SetWeCom',
        SetOceanengine: 'DpBackend.Configs.SetOceanengine',
        SetWebCall: 'DpBackend.Configs.SetWebCall',
        SetWeChatApplet: 'DpBackend.Configs.SetWeChatApplet',
        CheckWeCom: 'DpBackend.Configs.CheckWeCom',
        CheckOceanengine: 'DpBackend.Configs.CheckOceanengine',
        CheckWebCall: 'DpBackend.Configs.CheckWebCall',
        CheckWeChatApplet: 'DpBackend.Configs.CheckWeChatApplet'
      }
    }
  }

  static rejectPermissionsList = {
    Config_Key_WeCom: [
      'DpBackend.WeChatCustomers',
      'DpBackend.WeChatCustomers.Info',
      'DpBackend.WeChatCustomers.List'
    ],
    Config_Key_Oceanengine: [
      'DpBackend.Statistics.Oceanengine'
    ],
    Config_Key_WebCall: [
      'DpBackend.WebCalls',
      'DpBackend.WebCalls.List',
      'DpBackend.WebCalls.ListMe',
      'DpBackend.WebCalls.Call'
    ],
    Config_Key_WeChatApplet: [
      'DpBackend.Evaluations',
      'DpBackend.Evaluations.List',
      'DpBackend.Evaluations.ListAll',
      'DpBackend.Evaluations.Get',
      'DpBackend.Evaluations.Add',
      'DpBackend.Evaluations.Edit',
      'DpBackend.Evaluations.Del',
      'DpBackend.Evaluations.Using',
      'DpBackend.Evaluations.Cancel',
      'DpBackend.Evaluations.Dimensions',
      'DpBackend.Evaluations.AddDimension',
      'DpBackend.Evaluations.EditDimension',
      'DpBackend.Evaluations.DelDimension',
      'DpBackend.Evaluations.DimensionScoreRanges',
      'DpBackend.Evaluations.AddDimensionScoreRange',
      'DpBackend.Evaluations.EvaluationQuestion',
      'DpBackend.Evaluations.AddEvaluationQuestion',
      'DpBackend.Evaluations.EvaluationQuestionScore',
      'DpBackend.Evaluations.EvaluationCustomers',
      'DpBackend.Evaluations.PdfFile',
      'DpBackend.Questions',
      'DpBackend.Questions.List',
      'DpBackend.Questions.Get',
      'DpBackend.Questions.Add',
      'DpBackend.Questions.Edit',
      'DpBackend.Questions.Del',
      'DpBackend.Questions.Using',
      'DpBackend.Questions.Cancel',
      'DpBackend.Questions.ListUsing'

    ]
  }

  static hasPermission = (permissions: IPermissionData[], permission: string) => {
    if (permission) {
      return BooleanUtil.filterBool(permissions.find(p => permission === p.name)?.isGranted)
    } else {
      return true
    }
  }
}
