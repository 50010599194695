/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setting': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.983 10.12l-.062.91-.826.382a3.385 3.385 0 00-1.522 1.406 3.461 3.461 0 00-.45 2.04l.08.903-.752.506c-.298.2-.607.38-.93.538l-.825.406-.748-.536a3.322 3.322 0 00-1.95-.626 3.326 3.326 0 00-1.949.625l-.748.536-.825-.407a7.987 7.987 0 01-.929-.538l-.751-.507.08-.902a3.478 3.478 0 00-.45-2.042 3.395 3.395 0 00-1.52-1.403l-.827-.383-.062-.908a7.68 7.68 0 010-1.029l.062-.908.826-.383a3.388 3.388 0 001.52-1.405c.356-.618.513-1.33.45-2.041l-.08-.903.753-.506c.296-.2.607-.38.93-.538L7.302 2l.747.537c.57.408 1.244.625 1.95.625a3.326 3.326 0 001.949-.624l.747-.536.825.406c.323.159.635.34.933.54l.75.507-.078.9a3.48 3.48 0 00.448 2.042c.349.614.88 1.105 1.521 1.403l.827.383.06.908c.024.343.024.687 0 1.03zm-1.519-.513c0-.138-.005-.275-.014-.413a4.93 4.93 0 01-2.207-2.033 5.01 5.01 0 01-.647-2.94 6.429 6.429 0 00-.753-.435A4.86 4.86 0 0110 4.698a4.863 4.863 0 01-2.845-.913 6.416 6.416 0 00-.75.434 5.009 5.009 0 01-.646 2.939A4.923 4.923 0 013.55 9.194c-.018.274-.018.55 0 .823a4.93 4.93 0 012.208 2.033c.512.89.738 1.917.648 2.941.24.161.49.307.749.434a4.862 4.862 0 012.844-.912 4.86 4.86 0 012.844.913c.26-.127.511-.272.75-.433a4.998 4.998 0 01.648-2.94 4.917 4.917 0 012.209-2.036 5.69 5.69 0 00.014-.41zM10 12.677a3.072 3.072 0 010-6.143 3.072 3.072 0 010 6.144zm0-1.535a1.536 1.536 0 100-3.072 1.536 1.536 0 100 3.072z" _fill="#606266" fill-rule="nonzero"/>'
  }
})
