/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendWeComDepartmentsCreateWeComDepartmentDto } from '../models';
import { DpBackendWeComDepartmentsEditWeComDepartmentDto } from '../models';
import { DpBackendWeComDepartmentsSetDepartmentLeaderDto } from '../models';
import { DpBackendWeComDepartmentsWeComDepartmentDto } from '../models';
import { DpBackendWeComDepartmentsWeComDepartmentTreeDto } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdDelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppDepartmentIdDelPost.');
            }
            const localVarPath = `/api/app/department/{id}/del`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsEditWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdEditNamePost: async (id: string, body?: DpBackendWeComDepartmentsEditWeComDepartmentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppDepartmentIdEditNamePost.');
            }
            const localVarPath = `/api/app/department/{id}/edit-name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsSetDepartmentLeaderDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdLeaderPost: async (id: string, body?: DpBackendWeComDepartmentsSetDepartmentLeaderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppDepartmentIdLeaderPost.');
            }
            const localVarPath = `/api/app/department/{id}/leader`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendWeComDepartmentsCreateWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentPost: async (body?: DpBackendWeComDepartmentsCreateWeComDepartmentDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentTreeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/department/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DpBackendWeComDepartmentsWeComDepartmentDto>>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentIdDelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentIdDelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsEditWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentIdEditNamePost(id: string, body?: DpBackendWeComDepartmentsEditWeComDepartmentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentIdEditNamePost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsSetDepartmentLeaderDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentIdLeaderPost(id: string, body?: DpBackendWeComDepartmentsSetDepartmentLeaderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentIdLeaderPost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendWeComDepartmentsCreateWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentPost(body?: DpBackendWeComDepartmentsCreateWeComDepartmentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppDepartmentTreeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendWeComDepartmentsWeComDepartmentTreeDto>> {
            const localVarAxiosArgs = await DepartmentApiAxiosParamCreator(configuration).apiAppDepartmentTreeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentGet(options?: any): AxiosPromise<Array<DpBackendWeComDepartmentsWeComDepartmentDto>> {
            return DepartmentApiFp(configuration).apiAppDepartmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdDelPost(id: string, options?: any): AxiosPromise<boolean> {
            return DepartmentApiFp(configuration).apiAppDepartmentIdDelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsEditWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdEditNamePost(id: string, body?: DpBackendWeComDepartmentsEditWeComDepartmentDto, options?: any): AxiosPromise<boolean> {
            return DepartmentApiFp(configuration).apiAppDepartmentIdEditNamePost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendWeComDepartmentsSetDepartmentLeaderDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentIdLeaderPost(id: string, body?: DpBackendWeComDepartmentsSetDepartmentLeaderDto, options?: any): AxiosPromise<boolean> {
            return DepartmentApiFp(configuration).apiAppDepartmentIdLeaderPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendWeComDepartmentsCreateWeComDepartmentDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentPost(body?: DpBackendWeComDepartmentsCreateWeComDepartmentDto, options?: any): AxiosPromise<string> {
            return DepartmentApiFp(configuration).apiAppDepartmentPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppDepartmentTreeGet(options?: any): AxiosPromise<DpBackendWeComDepartmentsWeComDepartmentTreeDto> {
            return DepartmentApiFp(configuration).apiAppDepartmentTreeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentGet(options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentIdDelPost(id: string, options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentIdDelPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {DpBackendWeComDepartmentsEditWeComDepartmentDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentIdEditNamePost(id: string, body?: DpBackendWeComDepartmentsEditWeComDepartmentDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentIdEditNamePost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {DpBackendWeComDepartmentsSetDepartmentLeaderDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentIdLeaderPost(id: string, body?: DpBackendWeComDepartmentsSetDepartmentLeaderDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentIdLeaderPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendWeComDepartmentsCreateWeComDepartmentDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentPost(body?: DpBackendWeComDepartmentsCreateWeComDepartmentDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiAppDepartmentTreeGet(options?: any) {
        return DepartmentApiFp(this.configuration).apiAppDepartmentTreeGet(options).then((request) => request(this.axios, this.basePath));
    }
}
