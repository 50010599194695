/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendQuestionsAddQuestionDto } from '../models';
import { DpBackendQuestionsEditQuestionDto } from '../models';
import { DpBackendQuestionsQuestionDto } from '../models';
import { DpBackendQuestionsQuestionSelectItemDto } from '../models';
import { DpBackendQuestionsQuestionStatus } from '../models';
import { VoloAbpApplicationDtosPagedResultDto1DpBackendQuestionsQuestionListDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {Array&lt;string&gt;} [evaluationIds] 
         * @param {Array&lt;DpBackendQuestionsQuestionStatus&gt;} [statusList] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionGet: async (filter?: string, evaluationIds?: Array<string>, statusList?: Array<DpBackendQuestionsQuestionStatus>, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (evaluationIds) {
                evaluationIds?.forEach((evaluationId, index) => {
                    localVarQueryParameter[`EvaluationIds[${index}]`] = evaluationId;
                })
            }

            if (statusList) {
                statusList?.forEach((status, index) => {
                    localVarQueryParameter[`StatusList[${index}]`] = status;
                })
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdCancelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppQuestionIdCancelPost.');
            }
            const localVarPath = `/api/app/question/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdDelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppQuestionIdDelPost.');
            }
            const localVarPath = `/api/app/question/{id}/del`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendQuestionsEditQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdEditPost: async (id: string, body?: DpBackendQuestionsEditQuestionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppQuestionIdEditPost.');
            }
            const localVarPath = `/api/app/question/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppQuestionIdGet.');
            }
            const localVarPath = `/api/app/question/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdUsingPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppQuestionIdUsingPost.');
            }
            const localVarPath = `/api/app/question/{id}/using`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendQuestionsAddQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionPost: async (body?: DpBackendQuestionsAddQuestionDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionUsingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/question/using`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {Array&lt;string&gt;} [evaluationIds] 
         * @param {Array&lt;DpBackendQuestionsQuestionStatus&gt;} [statusList] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionGet(filter?: string, evaluationIds?: Array<string>, statusList?: Array<DpBackendQuestionsQuestionStatus>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendQuestionsQuestionListDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionGet(filter, evaluationIds, statusList, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionIdCancelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionIdCancelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionIdDelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionIdDelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendQuestionsEditQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionIdEditPost(id: string, body?: DpBackendQuestionsEditQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionIdEditPost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendQuestionsQuestionDto>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionIdUsingPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionIdUsingPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendQuestionsAddQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionPost(body?: DpBackendQuestionsAddQuestionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppQuestionUsingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DpBackendQuestionsQuestionSelectItemDto>>> {
            const localVarAxiosArgs = await QuestionApiAxiosParamCreator(configuration).apiAppQuestionUsingGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [filter] 
         * @param {Array&lt;string&gt;} [evaluationIds] 
         * @param {Array&lt;DpBackendQuestionsQuestionStatus&gt;} [statusList] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionGet(filter?: string, evaluationIds?: Array<string>, statusList?: Array<DpBackendQuestionsQuestionStatus>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendQuestionsQuestionListDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull> {
            return QuestionApiFp(configuration).apiAppQuestionGet(filter, evaluationIds, statusList, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdCancelPost(id: string, options?: any): AxiosPromise<boolean> {
            return QuestionApiFp(configuration).apiAppQuestionIdCancelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdDelPost(id: string, options?: any): AxiosPromise<boolean> {
            return QuestionApiFp(configuration).apiAppQuestionIdDelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DpBackendQuestionsEditQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdEditPost(id: string, body?: DpBackendQuestionsEditQuestionDto, options?: any): AxiosPromise<string> {
            return QuestionApiFp(configuration).apiAppQuestionIdEditPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdGet(id: string, options?: any): AxiosPromise<DpBackendQuestionsQuestionDto> {
            return QuestionApiFp(configuration).apiAppQuestionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionIdUsingPost(id: string, options?: any): AxiosPromise<boolean> {
            return QuestionApiFp(configuration).apiAppQuestionIdUsingPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendQuestionsAddQuestionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionPost(body?: DpBackendQuestionsAddQuestionDto, options?: any): AxiosPromise<string> {
            return QuestionApiFp(configuration).apiAppQuestionPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppQuestionUsingGet(options?: any): AxiosPromise<Array<DpBackendQuestionsQuestionSelectItemDto>> {
            return QuestionApiFp(configuration).apiAppQuestionUsingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * 
     * @param {string} [filter] 
     * @param {Array&lt;string&gt;} [evaluationIds] 
     * @param {Array&lt;DpBackendQuestionsQuestionStatus&gt;} [statusList] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionGet(filter?: string, evaluationIds?: Array<string>, statusList?: Array<DpBackendQuestionsQuestionStatus>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionGet(filter, evaluationIds, statusList, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionIdCancelPost(id: string, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionIdCancelPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionIdDelPost(id: string, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionIdDelPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {DpBackendQuestionsEditQuestionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionIdEditPost(id: string, body?: DpBackendQuestionsEditQuestionDto, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionIdEditPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionIdGet(id: string, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionIdUsingPost(id: string, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionIdUsingPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendQuestionsAddQuestionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionPost(body?: DpBackendQuestionsAddQuestionDto, options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiAppQuestionUsingGet(options?: any) {
        return QuestionApiFp(this.configuration).apiAppQuestionUsingGet(options).then((request) => request(this.axios, this.basePath));
    }
}
