/**
 * @Author: siyukok
 * @Date: 2022/2/10 2:08 PM
 * @Version 1.0
 */
export default class BooleanUtil {
  // js中除'',"",undefined,null,false,0,NaN转化为boolean类型均为true
  static filterBool(bool: any): boolean {
    return bool === true || bool === 'true'
  }
}
