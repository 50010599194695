/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
import { VoloAbpPermissionManagementGetPermissionListResultDto } from '../models';
import { VoloAbpPermissionManagementUpdatePermissionsDto } from '../models';
/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPermissionManagementPermissionsGet: async (providerName?: string, providerKey?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/permission-management/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (providerKey !== undefined) {
                localVarQueryParameter['providerKey'] = providerKey;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VoloAbpPermissionManagementUpdatePermissionsDto} [body] 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPermissionManagementPermissionsPut: async (body?: VoloAbpPermissionManagementUpdatePermissionsDto, providerName?: string, providerKey?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/permission-management/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }

            if (providerKey !== undefined) {
                localVarQueryParameter['providerKey'] = providerKey;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPermissionManagementPermissionsGet(providerName?: string, providerKey?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpPermissionManagementGetPermissionListResultDto>> {
            const localVarAxiosArgs = await PermissionsApiAxiosParamCreator(configuration).apiPermissionManagementPermissionsGet(providerName, providerKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VoloAbpPermissionManagementUpdatePermissionsDto} [body] 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPermissionManagementPermissionsPut(body?: VoloAbpPermissionManagementUpdatePermissionsDto, providerName?: string, providerKey?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PermissionsApiAxiosParamCreator(configuration).apiPermissionManagementPermissionsPut(body, providerName, providerKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPermissionManagementPermissionsGet(providerName?: string, providerKey?: string, options?: any): AxiosPromise<VoloAbpPermissionManagementGetPermissionListResultDto> {
            return PermissionsApiFp(configuration).apiPermissionManagementPermissionsGet(providerName, providerKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VoloAbpPermissionManagementUpdatePermissionsDto} [body] 
         * @param {string} [providerName] 
         * @param {string} [providerKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPermissionManagementPermissionsPut(body?: VoloAbpPermissionManagementUpdatePermissionsDto, providerName?: string, providerKey?: string, options?: any): AxiosPromise<void> {
            return PermissionsApiFp(configuration).apiPermissionManagementPermissionsPut(body, providerName, providerKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [providerName] 
     * @param {string} [providerKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public apiPermissionManagementPermissionsGet(providerName?: string, providerKey?: string, options?: any) {
        return PermissionsApiFp(this.configuration).apiPermissionManagementPermissionsGet(providerName, providerKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {VoloAbpPermissionManagementUpdatePermissionsDto} [body] 
     * @param {string} [providerName] 
     * @param {string} [providerKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public apiPermissionManagementPermissionsPut(body?: VoloAbpPermissionManagementUpdatePermissionsDto, providerName?: string, providerKey?: string, options?: any) {
        return PermissionsApiFp(this.configuration).apiPermissionManagementPermissionsPut(body, providerName, providerKey, options).then((request) => request(this.axios, this.basePath));
    }
}
