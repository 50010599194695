/**
 * @Author: siyukok
 * @Date: 2022/3/2 10:40 AM
 * @Version 1.0
 */
export default class ArrayUtil {
  static filter<T>(arr: any): T[] {
    return Array.isArray(arr) ? arr : []
  }
}
