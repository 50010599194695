/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'marketing': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M16.332 11.993l.675 1.34-6.633 3.344a.75.75 0 01-.564.045l-.109-.044-6.707-3.341.67-1.343 6.37 3.174 6.298-3.175zm0-3.5l.675 1.34-6.633 3.344a.75.75 0 01-.564.045l-.109-.044-6.707-3.341.67-1.343 6.37 3.174 6.298-3.175zm-6.63-6.165a.75.75 0 01.67.002l5.964 2.993a.75.75 0 01.002 1.34L10.374 9.67a.75.75 0 01-.673.001L3.666 6.664a.75.75 0 010-1.343zm.331 1.51L5.685 5.994l4.349 2.167 4.297-2.167-4.298-2.156z" _fill="#606266" fill-rule="nonzero"/>'
  }
})
