import { UserModule } from '@/store/modules/user'
import UserApi from '@/api/user'
import UserStorage from '@/storages/user'

/* eslint-disable camelcase */
/**
 * @Author: siyukok
 * @Date: 2022/2/10 11:25 AM
 * @Version 1.0
 */
export default class UserService {
  // 登出
  static async logout(useApi = true) {
    UserStorage.removeToken()
    UserStorage.removeExpireAt()
    UserStorage.removeRefreshToken()
    await UserModule.LogOut()
    if (useApi) await UserApi.logout()
  }

  // 使用refreshToken重新获取accessToken,距离过期还有两小时
  static async checkAndRefreshToken() {
    if (UserModule.oauth.refreshToken && UserModule.oauth.expireAt > 0 && (UserModule.oauth.expireAt - Math.ceil(Date.now() / 1000)) < 3600 * 2) {
      const { data } = await UserApi.getTokenByRefreshToken(UserModule.oauth.refreshToken)
      if (!data) {
        return
      }
      // todo check refreshToken is working
      this.processTokenData(data)
    }
  }

  // 根据授权码获取token
  static async getTokenByCode(code: string, onFail: Function, onSuccess: Function) {
    const { data } = await UserApi.getTokenByCode(code)
    if (!data) {
      onFail && onFail()
      return
    }
    this.processTokenData(data)
    onSuccess && onSuccess()
  }

  // 根据账号密码获取token
  static async getTokenByPassword(username: string, password: string, onFail: Function, onSuccess: Function) {
    const { data } = await UserApi.getTokenByPassword(username, password)
    if (!data) {
      onFail && onFail()
      return
    }
    this.processTokenData(data)
    onSuccess && onSuccess()
  }

  static processTokenData(data: any) {
    const {
      access_token: accessToken,
      expires_in,
      refresh_token: refreshToken
    } = data
    const expireAt = Math.floor(Date.now().valueOf() / 1000) + Math.floor(expires_in / 1000)
    UserModule.DpLogin({
      accessToken,
      expireAt,
      refreshToken
    })
    UserStorage.accessToken = accessToken
    UserStorage.refreshToken = refreshToken
    UserStorage.expireAt = expireAt
  }
}
