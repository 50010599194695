/**
 * @Author: siyukok
 * @Date: 2022/1/21 5:01 PM
 * @Version 1.0
 */
import * as wx from '@wecom/jssdk'
import WecomApi from '@/api/wecom'
import { setWecomAuthRedirect } from '@/utils/cookies'
import Constant from '@/constant/constant'
import UserStorage from '@/storages/user'
// import { WeComApi } from '@/proxy'

export default class WecomUtil {
  static authState = 'wecom_auth'

  // static corpId = process.env.VUE_APP_CORP_ID
  static corpId = UserStorage.corpId

  static get instance() {
    return wx
  }

  static genSignature(ticket: string, nonceStr: string, timestamp: number) {
    return `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${Constant.APP_HOST}`
  }

  // static async getCorpId(corpid: string) {
  //   UserStorage.corpId = corpid
  //   const { data } = await new WeComApi().apiAppWeComTenantIdCorpIdGet(corpid)
  //   if (data) {
  //     Constant.TENANT_ID = data
  //   }
  // }

  static init() {
    wx.register({
      corpId: this.corpId ?? '',
      agentId: process.env.VUE_APP_CORP_AGENT_ID,
      jsApiList: [
        // 获取进入的场景
        'getContext',
        // 获取外部联系人即客户的相关信息
        'getCurExternalContact',
        // 发送会话消息
        'sendChatMessage',
        // 获取当前客户群的群ID
        'getCurExternalChat',
        // 进入添加客户页面
        'navigateToAddCustomer',
        // 打开会话
        'openEnterpriseChat',
        // 进入添加客户界面
        'navigateToAddCustomer'
      ],
      async getConfigSignature(url) {
        console.log('getConfigSignature', url)
        const signRes = await WecomApi.getWecomSign(url)
        return {
          ...signRes.data
        }
      },
      onConfigSuccess(res) {
        console.log('success', res)
      },
      onConfigFail(res) {
        console.log('fail', res)
      },
      async getAgentConfigSignature(url) {
        console.log('getAgentConfigSignature', url)
        const signRes = await WecomApi.getWecomAppSign(url)
        return {
          ...signRes.data
        }
      },
      async onAgentConfigSuccess(res) {
        console.log('success', res)
      },
      onAgentConfigFail(res) {
        console.log('fail', res)
      }
    })
  }

  static genAuthUrl(corpId = WecomUtil.corpId, redirectUri = `${Constant.APP_HOST}#/login`) {
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${this.authState}#wechat_redirect`
  }

  static authWeixin(redirect = '') {
    setWecomAuthRedirect(redirect)
    window.location.href = this.genAuthUrl()
  }

  static isWxWorkEnv(): boolean {
    return /wxwork/i.test(window.navigator.userAgent.toLowerCase())
  }
}
