/**
 * @Author: siyukok
 * @Date: 2022/3/2 10:11 AM
 * @Version 1.0
 */
import SystemApi from '@/api/system'
import { AppModule } from '@/store/modules/app'
import { IDictGroupData } from '@/api/types'
import Constant from '@/constant/constant'

export default class AppService {
  static async refreshAppDictGroups() {
    const { data } = await SystemApi.getDictGroups()
    if (data) {
      AppModule.SetDictGroups(data)
    }
  }

  static get ContactMethods(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.Contact)
  }

  static get FollowClueWays(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.FollowClueWays)
  }

  static get ClueSucceedCauses(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.ClueSucceedCauses)
  }

  static get ClueFailCauses(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.ClueFailCauses)
  }

  static get ClueReturnCauses(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.ClueReturnCauses)
  }

  static get ClueInvalidCauses(): IDictGroupData | undefined {
    return AppModule.dictGroups.find(value => value.name === Constant.DictKeys.ClueInvalidCauses)
  }
}
