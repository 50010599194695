/**
 * @Author: siyukok
 * @Date: 2022/1/30 6:31 PM
 * @Version 1.0
 */
export default class ObjectUtil {
  static toString(obj: any) {
    if (typeof obj === 'object') {
      let objStr = ''
      for (const key in obj) {
        objStr += `${key}:${obj[key]};`
      }
      return objStr
    }
    return ''
  }

  static getProperty<T>(key: any, obj: any): T | undefined {
    if (typeof key !== 'string' || typeof obj !== 'object') {
      return
    }
    if (!obj[key]) {
      return
    }
    return obj[key] as T
  }

  static deepFreeze(obj: object) {
    const propNames = Object.getOwnPropertyNames(obj)
    propNames.forEach(function(name) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const prop = obj[name]
      if (typeof prop === 'object' && prop !== null) {
        ObjectUtil.deepFreeze(prop)
      }
    })
    return Object.freeze(obj)
  }
}
