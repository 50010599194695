/**
 * @Author: siyukok
 * @Date: 2022/1/28 8:54 PM
 * @Version 1.0
 */
export default class Routes {
  static Login = '/login'
  static AuthorizeCallback = '/authorize/callback'
  static NotMatch = '/404'
  static Root = '/'

  static Data = '/data'
  static DataMarketing = '/data/marketing'

  static MarketingFlowAnalyze = '/marketing-flow-analyze'
  static MarketingFlowAnalyzeOcean = '/marketing-flow-analyze/ocean'

  static Wecom = '/wecom'
  static WecomMobileEntry = '/wecom/mobile-entry'
  static WecomCustomer = '/wecom/customer'
  static WecomLostCustomer = '/wecom/lost-customer'
  static WecomDynamicQrcode = '/wecom/dynamic-qrcode'
  static WecomGroupDynamicQrcode = '/wecom/group-dynamic-qrcode'
  static WecomMaterialLibrary = '/wecom/material-library'

  static Customer = '/customer'
  static CustomerCenter = '/customer/center'
  static CustomerClues = '/customer/clues'
  static CustomerMyClues = '/customer/my-clues'
  static CustomerMyCluesMobile = '/customer/my-clues/mobile'
  static CustomerUndistributedClues = '/customer/undistributed-clues'
  static CustomerCluesCreatedByMe = '/customer/clues-created-by-me'
  static CustomerIdentity = '/customer/identity'
  static CustomerCallRecords = '/customer/call/records'
  static CustomerCallMyRecords = '/customer/call/my-records'

  static Settings = '/settings'
  static SettingsTag = '/settings/tag'
  static SettingsDictionary = '/settings/dictionary'
  static SettingsAgreement = '/settings/agreement'
  static SettingsAudit = '/settings/audit'
  static SettingsRoles = '/settings/roles'
  static SettingsSystem = '/settings/system'
  static SettingsThirdParty = '/settings/third-party'

  static Marketing = '/marketing'
  static MarketingProduct = '/marketing/product'
  static MarketingOrder = '/marketing/order'
  static MarketingForm = '/marketing/form'

  static Auth = '/auth'
  static AuthChannel = '/auth/channel'
  static AuthsMembers = '/auth/members'
  static AuthsRole = '/auth/role'

  static Operate = '/operate'
  static OperateContract = '/operate/contract'
  static OperateActivity = '/operate/activity'

  static Content = '/content'
  static QuestionBank = '/content/question-bank'
  static Evaluation = '/content/evaluation'

  // 默认/wecom为首页
  static Home = Routes.CustomerMyClues
}
