/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { Configuration } from '../configuration'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base'
import { DpBackendStatisticsStatisticDto, DpBackendStatisticsStatisticOceanengineDto } from '../models'
import { DpBackendStatisticsTimeDimensionType } from '../models'
import { DpBackendWeComCorpUsersWeComCorpUserNameDto } from '../models'
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models'
// @ts-ignore
import DateUtil from '@/utils/date'

/**
 * StatisticApi - axios parameter creator
 * @export
 */
export const StatisticApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticCorpUsersGet: async(options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/app/statistic/corp-users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? await configuration.accessToken('oauth2', ['DpBackend'])
          : await configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + localVarAccessTokenValue
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {DpBackendStatisticsTimeDimensionType} [timeType]
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {string} [clueStatus]
     * @param {string} [abpUserIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticInfoGet: async(timeType?: DpBackendStatisticsTimeDimensionType, start?: Date, end?: Date, clueStatus?: string, abpUserIds?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/app/statistic/info`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? await configuration.accessToken('oauth2', ['DpBackend'])
          : await configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + localVarAccessTokenValue
      }

      if (timeType !== undefined) {
        localVarQueryParameter['TimeType'] = timeType
      }

      if (start !== undefined) {
        localVarQueryParameter['Start'] = (start as any instanceof Date) ?
          DateUtil.dateFormat(start) :
          start
      }

      if (end !== undefined) {
        localVarQueryParameter['End'] = (end as any instanceof Date) ?
          DateUtil.dateFormat(end) :
          end
      }

      if (clueStatus !== undefined) {
        localVarQueryParameter['ClueStatus'] = clueStatus
      }

      if (abpUserIds !== undefined) {
        localVarQueryParameter['AbpUserIds'] = abpUserIds
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticOceanengineGet: async(start?: Date, end?: Date, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/app/statistic/oceanengine`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com')
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      if (configuration && configuration.accessToken) {
        const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
          ? await configuration.accessToken('oauth2', ['DpBackend'])
          : await configuration.accessToken
        localVarHeaderParameter['Authorization'] = 'Bearer ' + localVarAccessTokenValue
      }

      if (start !== undefined) {
        localVarQueryParameter['Start'] = (start as any instanceof Date) ?
          DateUtil.dateFormat(start) :
          start
      }

      if (end !== undefined) {
        localVarQueryParameter['End'] = (end as any instanceof Date) ?
          DateUtil.dateFormat(end) :
          end
      }

      const query = new URLSearchParams(localVarUrlObj.search)
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key])
      }
      for (const key in options.query) {
        query.set(key, options.query[key])
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString()
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      }
    }
  }
}

/**
 * StatisticApi - functional programming interface
 * @export
 */
export const StatisticApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAppStatisticCorpUsersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DpBackendWeComCorpUsersWeComCorpUserNameDto>>> {
      const localVarAxiosArgs = await StatisticApiAxiosParamCreator(configuration).apiAppStatisticCorpUsersGet(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {DpBackendStatisticsTimeDimensionType} [timeType]
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {string} [clueStatus]
     * @param {string} [abpUserIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAppStatisticInfoGet(timeType?: DpBackendStatisticsTimeDimensionType, start?: Date, end?: Date, clueStatus?: string, abpUserIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendStatisticsStatisticDto>> {
      const localVarAxiosArgs = await StatisticApiAxiosParamCreator(configuration).apiAppStatisticInfoGet(timeType, start, end, clueStatus, abpUserIds, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAppStatisticOceanengineGet(start?: Date, end?: Date, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendStatisticsStatisticOceanengineDto>> {
      const localVarAxiosArgs = await StatisticApiAxiosParamCreator(configuration).apiAppStatisticOceanengineGet(start, end, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * StatisticApi - factory interface
 * @export
 */
export const StatisticApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticCorpUsersGet(options?: any): AxiosPromise<Array<DpBackendWeComCorpUsersWeComCorpUserNameDto>> {
      return StatisticApiFp(configuration).apiAppStatisticCorpUsersGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {DpBackendStatisticsTimeDimensionType} [timeType]
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {string} [clueStatus]
     * @param {string} [abpUserIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticInfoGet(timeType?: DpBackendStatisticsTimeDimensionType, start?: Date, end?: Date, clueStatus?: string, abpUserIds?: string, options?: any): AxiosPromise<DpBackendStatisticsStatisticDto> {
      return StatisticApiFp(configuration).apiAppStatisticInfoGet(timeType, start, end, clueStatus, abpUserIds, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAppStatisticOceanengineGet(start?: Date, end?: Date, options?: any): AxiosPromise<DpBackendStatisticsStatisticOceanengineDto> {
      return StatisticApiFp(configuration).apiAppStatisticOceanengineGet(start, end, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * StatisticApi - object-oriented interface
 * @export
 * @class StatisticApi
 * @extends {BaseAPI}
 */
export class StatisticApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public apiAppStatisticCorpUsersGet(options?: any) {
    return StatisticApiFp(this.configuration).apiAppStatisticCorpUsersGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {DpBackendStatisticsTimeDimensionType} [timeType]
   * @param {Date} [start]
   * @param {Date} [end]
   * @param {string} [clueStatus]
   * @param {string} [abpUserIds]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public apiAppStatisticInfoGet(timeType?: DpBackendStatisticsTimeDimensionType, start?: Date, end?: Date, clueStatus?: string, abpUserIds?: string, options?: any) {
    return StatisticApiFp(this.configuration).apiAppStatisticInfoGet(timeType, start, end, clueStatus, abpUserIds, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Date} [start]
   * @param {Date} [end]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticApi
   */
  public apiAppStatisticOceanengineGet(start?: Date, end?: Date, options?: any) {
    return StatisticApiFp(this.configuration).apiAppStatisticOceanengineGet(start, end, options).then((request) => request(this.axios, this.basePath))
  }
}
