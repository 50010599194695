/**
 * @Author: siyukok
 * @Date: 2022/2/9 5:10 PM
 * @Version 1.0
 */
export default class NumberUtil {
  static parseInt(num: any) {
    const safeNum = Number.parseInt(num)
    return isNaN(safeNum) ? 0 : safeNum
  }

  static parseFloat(num: any) {
    const safeNum = Number.parseFloat(num)
    return isNaN(safeNum) ? 0 : safeNum
  }

  static formatTime(seconds: number): string {
    if (!seconds) {
      return '00:00'
    }
    let hour: number | string = Math.floor(seconds / 3600)
    hour = hour > 9 ? hour : `0${hour}`
    let minute: number | string = Math.floor((seconds % 3600) / 60)
    minute = minute > 9 ? minute : `0${minute}`
    let second: number | string = Math.floor(seconds % 60)
    second = second > 9 ? second : `0${second}`
    if (hour > 0) {
      return `${hour}:${minute}:${second}`
    }
    return `${minute}:${second}`
  }
}
