/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendChannelsAddChannelDto } from '../models';
import { DpBackendChannelsChannelDateTimeType } from '../models';
import { DpBackendChannelsChannelDto } from '../models';
import { DpBackendChannelsSelectItemChannelDto } from '../models';
import { DpBackendChannelsSignChannelDto } from '../models';
import { VoloAbpApplicationDtosPagedResultDto1DpBackendChannelsChannelDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * ChannelApi - axios parameter creator
 * @export
 */
export const ChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {DpBackendChannelsChannelDateTimeType} [dateTimeType]
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {boolean} [isSign]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelGet: async (filter?: string, dateTimeType?: DpBackendChannelsChannelDateTimeType, start?: string, end?: string, isSign?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (dateTimeType !== undefined) {
                localVarQueryParameter['DateTimeType'] = dateTimeType;
            }

            if (start !== undefined) {
                localVarQueryParameter['Start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['End'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (isSign !== undefined) {
                localVarQueryParameter['IsSign'] = isSign;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdDelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppChannelIdDelPost.');
            }
            const localVarPath = `/api/app/channel/{id}/del`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdEditPost: async (id: string, body?: DpBackendChannelsAddChannelDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppChannelIdEditPost.');
            }
            const localVarPath = `/api/app/channel/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppChannelIdGet.');
            }
            const localVarPath = `/api/app/channel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsSignChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdSignPost: async (id: string, body?: DpBackendChannelsSignChannelDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppChannelIdSignPost.');
            }
            const localVarPath = `/api/app/channel/{id}/sign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelPost: async (body?: DpBackendChannelsAddChannelDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelSignedListGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/channel/signed-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelApi - functional programming interface
 * @export
 */
export const ChannelApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {DpBackendChannelsChannelDateTimeType} [dateTimeType]
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {boolean} [isSign]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelGet(filter?: string, dateTimeType?: DpBackendChannelsChannelDateTimeType, start?: string, end?: string, isSign?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendChannelsChannelDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelGet(filter, dateTimeType, start, end, isSign, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelIdDelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelIdDelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelIdEditPost(id: string, body?: DpBackendChannelsAddChannelDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelIdEditPost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendChannelsChannelDto>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsSignChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelIdSignPost(id: string, body?: DpBackendChannelsSignChannelDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelIdSignPost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelPost(body?: DpBackendChannelsAddChannelDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppChannelSignedListGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DpBackendChannelsSelectItemChannelDto>>> {
            const localVarAxiosArgs = await ChannelApiAxiosParamCreator(configuration).apiAppChannelSignedListGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChannelApi - factory interface
 * @export
 */
export const ChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {DpBackendChannelsChannelDateTimeType} [dateTimeType]
         * @param {Date} [start]
         * @param {Date} [end]
         * @param {boolean} [isSign]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelGet(filter?: string, dateTimeType?: DpBackendChannelsChannelDateTimeType, start?: string, end?: string, isSign?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendChannelsChannelDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull> {
            return ChannelApiFp(configuration).apiAppChannelGet(filter, dateTimeType, start, end, isSign, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdDelPost(id: string, options?: any): AxiosPromise<boolean> {
            return ChannelApiFp(configuration).apiAppChannelIdDelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdEditPost(id: string, body?: DpBackendChannelsAddChannelDto, options?: any): AxiosPromise<string> {
            return ChannelApiFp(configuration).apiAppChannelIdEditPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdGet(id: string, options?: any): AxiosPromise<DpBackendChannelsChannelDto> {
            return ChannelApiFp(configuration).apiAppChannelIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendChannelsSignChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelIdSignPost(id: string, body?: DpBackendChannelsSignChannelDto, options?: any): AxiosPromise<string> {
            return ChannelApiFp(configuration).apiAppChannelIdSignPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DpBackendChannelsAddChannelDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelPost(body?: DpBackendChannelsAddChannelDto, options?: any): AxiosPromise<string> {
            return ChannelApiFp(configuration).apiAppChannelPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppChannelSignedListGet(options?: any): AxiosPromise<Array<DpBackendChannelsSelectItemChannelDto>> {
            return ChannelApiFp(configuration).apiAppChannelSignedListGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelApi - object-oriented interface
 * @export
 * @class ChannelApi
 * @extends {BaseAPI}
 */
export class ChannelApi extends BaseAPI {
    /**
     *
     * @param {string} [filter]
     * @param {DpBackendChannelsChannelDateTimeType} [dateTimeType]
     * @param {Date} [start]
     * @param {Date} [end]
     * @param {boolean} [isSign]
     * @param {string} [sorting]
     * @param {number} [skipCount]
     * @param {number} [maxResultCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelGet(filter?: string, dateTimeType?: DpBackendChannelsChannelDateTimeType, start?: string, end?: string, isSign?: boolean, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelGet(filter, dateTimeType, start, end, isSign, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelIdDelPost(id: string, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelIdDelPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {DpBackendChannelsAddChannelDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelIdEditPost(id: string, body?: DpBackendChannelsAddChannelDto, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelIdEditPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelIdGet(id: string, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {DpBackendChannelsSignChannelDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelIdSignPost(id: string, body?: DpBackendChannelsSignChannelDto, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelIdSignPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {DpBackendChannelsAddChannelDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelPost(body?: DpBackendChannelsAddChannelDto, options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiAppChannelSignedListGet(options?: any) {
        return ChannelApiFp(this.configuration).apiAppChannelSignedListGet(options).then((request) => request(this.axios, this.basePath));
    }
}
