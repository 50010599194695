import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import Routes from '@/constant/routes'
import PermissionConst from '@/constant/permissions'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: Routes.Login,
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {
      hidden: true,
      title: '登录'
    }
  },
  {
    path: Routes.AuthorizeCallback,
    component: () => import(/* webpackChunkName: "auth" */ '@/views/authorize-callback/index.vue'),
    meta: {
      hidden: true,
      title: '微信授权...'
    }
  },
  {
    path: Routes.NotMatch,
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      hidden: true,
      title: '404'
    }
  },
  {
    path: Routes.Root,
    component: Layout,
    redirect: Routes.CustomerMyClues
    // children: [
    //   {
    //     path: Routes.Home.replace(Routes.Root, ''),
    //     component: () => import(/* webpackChunkName: "dashboard" */ '@/views/home/index.vue'),
    //     name: Routes.Home,
    //     meta: {
    //       title: '首页',
    //       icon: 'dashboard',
    //       affix: true
    //     }
    //   }
    // ]
  },
  {
    path: Routes.WecomMobileEntry,
    component: () => import('@/views/wecom/mobile-entry/index.vue'),
    meta: {
      title: '企微侧边栏',
      icon: 'example',
      hidden: true
    }
  },
  {
    path: Routes.CustomerMyCluesMobile,
    component: () => import('@/views/wecom/my-clues/index.vue'),
    meta: {
      title: '我的线索',
      icon: 'example',
      hidden: true
    }
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes: RouteConfig[] = [
  {
    path: '*',
    redirect: Routes.NotMatch,
    meta: { hidden: true }
  },
  {
    path: Routes.Data,
    component: Layout,
    redirect: Routes.DataMarketing,
    meta: {
      title: '数据中心',
      icon: 'data',
      permission: PermissionConst.Group.DpBackend.Statistics.Info
    },
    children: [
      {
        path: Routes.DataMarketing.replace(Routes.Data.concat('/'), ''),
        component: () => import('@/views/data/marketing/index.vue'),
        name: Routes.DataMarketing,
        meta: {
          title: '营销数据',
          permission: PermissionConst.Group.DpBackend.Statistics.Info
        }
      }
    ]
  },
  {
    path: Routes.MarketingFlowAnalyze,
    component: Layout,
    redirect: Routes.MarketingFlowAnalyzeOcean,
    meta: {
      title: '营销流程分析',
      icon: 'analyze',
      permission: PermissionConst.Group.DpBackend.Statistics.OceanEngine
    },
    children: [
      {
        path: Routes.MarketingFlowAnalyzeOcean.replace(Routes.MarketingFlowAnalyze.concat('/'), ''),
        component: () => import('@/views/marketing-flow-analyze/ocean/index.vue'),
        name: Routes.MarketingFlowAnalyzeOcean,
        meta: {
          title: '巨量引擎营销',
          permission: PermissionConst.Group.DpBackend.Statistics.OceanEngine
        }
      }
    ]
  },
  {
    path: Routes.Wecom,
    component: Layout,
    redirect: Routes.WecomCustomer,
    meta: {
      title: '企微中心',
      icon: 'wecom',
      permission: PermissionConst.Group.DpBackend.WeChatCustomers.List
    },
    children: [
      {
        path: Routes.WecomCustomer.replace(Routes.Wecom.concat('/'), ''),
        component: () => import('@/views/wecom/customer/index.vue'),
        name: Routes.WecomCustomer,
        meta: {
          title: '企微客户',
          permission: PermissionConst.Group.DpBackend.WeChatCustomers.List
        }
      }
      // {
      //   path: Routes.WecomLostCustomer.replace(Routes.Wecom.concat('/'), ''),
      //   component: () => import('@/views/wecom/lost-customer/index.vue'),
      //   name: Routes.WecomLostCustomer,
      //   meta: {
      //     title: '流失客户'
      //   }
      // },
      // {
      //   path: Routes.WecomDynamicQrcode.replace(Routes.Wecom.concat('/'), ''),
      //   component: () => import('@/views/wecom/dynamic-qrcode/index.vue'),
      //   name: Routes.WecomDynamicQrcode,
      //   meta: {
      //     title: '企微号活码'
      //   }
      // },
      // {
      //   path: Routes.WecomGroupDynamicQrcode.replace(Routes.Wecom.concat('/'), ''),
      //   component: () => import('@/views/wecom/group-dynamic-qrcode/index.vue'),
      //   name: Routes.WecomGroupDynamicQrcode,
      //   meta: {
      //     title: '企微群活码'
      //   }
      // },
      // {
      //   path: Routes.WecomMaterialLibrary.replace(Routes.Wecom.concat('/'), ''),
      //   component: () => import('@/views/wecom/material-library/index.vue'),
      //   name: Routes.WecomMaterialLibrary,
      //   meta: {
      //     title: '素材'
      //   }
      // }
    ]
  },
  {
    path: Routes.Customer,
    component: Layout,
    redirect: Routes.CustomerCenter,
    meta: {
      title: '客户中心',
      icon: 'customer',
      permissions: [
        PermissionConst.Group.DpBackend.Customers.List,
        PermissionConst.Group.DpBackend.Clues.List,
        PermissionConst.Group.DpBackend.Clues.ListMe,
        PermissionConst.Group.DpBackend.Clues.ListWait,
        PermissionConst.Group.DpBackend.Clues.ListMeCreated,
        PermissionConst.Group.DpBackend.CustomerIdentitys.List
      ]
    },
    children: [
      {
        path: Routes.CustomerCenter.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/center/index.vue'),
        name: Routes.CustomerCenter,
        meta: {
          title: '客户',
          permission: PermissionConst.Group.DpBackend.Customers.List
        }
      },
      {
        path: Routes.CustomerClues.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/clues/index.vue'),
        name: Routes.CustomerClues,
        meta: {
          title: '全部线索',
          permission: PermissionConst.Group.DpBackend.Clues.List
        }
      },
      {
        path: Routes.CustomerMyClues.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/my-clues/index.vue'),
        name: Routes.CustomerMyClues,
        meta: {
          title: '我的线索',
          permission: PermissionConst.Group.DpBackend.Clues.ListMe
        }
      },
      {
        path: Routes.CustomerUndistributedClues.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/undistributed-clues/index.vue'),
        name: Routes.CustomerUndistributedClues,
        meta: {
          title: '待分配线索',
          permission: PermissionConst.Group.DpBackend.Clues.ListWait
        }
      },
      {
        path: Routes.CustomerCluesCreatedByMe.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/clues-created-by-me/index.vue'),
        name: Routes.CustomerCluesCreatedByMe,
        meta: {
          title: '我创建的线索',
          permission: PermissionConst.Group.DpBackend.Clues.ListMeCreated
        }
      },
      {
        path: Routes.CustomerIdentity.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/identity/index.vue'),
        name: Routes.CustomerIdentity,
        meta: {
          title: '客户身份',
          permission: PermissionConst.Group.DpBackend.CustomerIdentitys.List
        }
      },
      {
        path: Routes.CustomerCallRecords.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/call-records/index.vue'),
        name: Routes.CustomerCallRecords,
        meta: {
          title: '通话记录',
          permission: PermissionConst.Group.DpBackend.WebCalls.List
        }
      },
      {
        path: Routes.CustomerCallMyRecords.replace(Routes.Customer.concat('/'), ''),
        component: () => import('@/views/customer/my-call-records/index.vue'),
        name: Routes.CustomerCallMyRecords,
        meta: {
          title: '我的通话记录',
          permission: PermissionConst.Group.DpBackend.WebCalls.ListMe
        }
      }
    ]
  },
  {
    path: Routes.Marketing,
    component: Layout,
    redirect: Routes.MarketingProduct,
    meta: {
      title: '营销中心',
      icon: 'marketing',
      permissions: [
        PermissionConst.Group.DpBackend.Products.Search,
        PermissionConst.Group.DpBackend.Orders.List
      ]
    },
    children: [
      {
        path: Routes.MarketingProduct.replace(Routes.Marketing.concat('/'), ''),
        component: () => import('@/views/marketing/product/index.vue'),
        name: Routes.MarketingProduct,
        meta: {
          title: '商品',
          permission: PermissionConst.Group.DpBackend.Products.Search
        }
      },
      {
        path: Routes.MarketingOrder.replace(Routes.Marketing.concat('/'), ''),
        component: () => import('@/views/marketing/order/index.vue'),
        name: Routes.MarketingOrder,
        meta: {
          title: '订单',
          permission: PermissionConst.Group.DpBackend.Orders.List
        }
      }
    ]
  },
  {
    path: Routes.Auth,
    component: Layout,
    redirect: Routes.AuthChannel,
    meta: {
      title: '团队管理',
      icon: 'auth',
      permission: PermissionConst.Group.DpBackend.Channels.List
    },
    children: [
      {
        path: Routes.AuthChannel.replace(Routes.Auth.concat('/'), ''),
        component: () => import('@/views/auth/channel/index.vue'),
        name: Routes.AuthChannel,
        meta: {
          title: '供应商入库',
          permission: PermissionConst.Group.DpBackend.Channels.List
        }
      },
      {
        path: Routes.AuthsMembers.replace(Routes.Auth.concat('/'), ''),
        component: () => import('@/views/auth/members/index.vue'),
        name: Routes.AuthsMembers,
        meta: {
          title: '成员',
          permission: PermissionConst.Group.DpBackend.Departments.List
        }
      },
      {
        path: Routes.AuthsRole.replace(Routes.Auth.concat('/'), ''),
        component: () => import('@/views/auth/role/index.vue'),
        name: Routes.AuthsRole,
        meta: {
          title: '角色',
          permissions: [
            PermissionConst.Group.AbpIdentity.Roles.Group,
            PermissionConst.Group.AbpIdentity.Roles.Create,
            PermissionConst.Group.AbpIdentity.Roles.Update,
            PermissionConst.Group.AbpIdentity.Roles.Delete,
            PermissionConst.Group.AbpIdentity.Roles.ManagePermissions
          ]
        }
      }
    ]
  },
  {
    path: Routes.Content,
    component: Layout,
    redirect: Routes.QuestionBank,
    meta: {
      title: '内容中心',
      icon: 'table',
      permissions: [
        PermissionConst.Group.DpBackend.Questions.List,
        PermissionConst.Group.DpBackend.Evaluations.List
      ]
    },
    children: [
      {
        path: Routes.QuestionBank.replace(Routes.Content.concat('/'), ''),
        component: () => import('@/views/content/question-bank/index.vue'),
        name: Routes.QuestionBank,
        meta: {
          title: '题库',
          permission: PermissionConst.Group.DpBackend.Questions.List
        }
      },
      {
        path: Routes.Evaluation.replace(Routes.Content.concat('/'), ''),
        component: () => import('@/views/content/evaluation/index.vue'),
        name: Routes.Evaluation,
        meta: {
          title: '测评',
          permission: PermissionConst.Group.DpBackend.Evaluations.List
        }
      }
    ]
  },
  {
    path: Routes.Operate,
    component: Layout,
    redirect: Routes.OperateActivity,
    meta: {
      title: '运营中心',
      icon: 'operate',
      permissions: [
        PermissionConst.Group.DpBackend.Activities.List,
        PermissionConst.Group.DpBackend.SmartContracts.List
      ]
    },
    children: [
      {
        path: Routes.OperateActivity.replace(Routes.Operate.concat('/'), ''),
        component: () => import('@/views/operate/activity/index.vue'),
        name: Routes.OperateActivity,
        meta: {
          title: '活动',
          permission: PermissionConst.Group.DpBackend.Activities.List
        }
      },
      {
        path: Routes.OperateContract.replace(Routes.Operate.concat('/'), ''),
        component: () => import('@/views/operate/contract/index.vue'),
        name: Routes.OperateContract,
        meta: {
          title: '智能合约模版',
          permission: PermissionConst.Group.DpBackend.SmartContracts.List
        }
      }
    ]
  },
  {
    path: Routes.Settings,
    component: Layout,
    redirect: Routes.SettingsTag,
    meta: {
      title: '系统管理',
      icon: 'setting',
      permissions: [
        PermissionConst.Group.DpBackend.Tags.ListAll,
        PermissionConst.Group.DpBackend.Departments.List
      ]
    },
    children: [
      {
        path: Routes.SettingsTag.replace(Routes.Settings.concat('/'), ''),
        component: () => import('@/views/settings/tag/index.vue'),
        name: Routes.SettingsTag,
        meta: {
          title: '标签',
          permission: PermissionConst.Group.DpBackend.Tags.ListAll
        }
      },
      {
        path: Routes.SettingsThirdParty.replace(Routes.Settings.concat('/'), ''),
        component: () => import('@/views/settings/third-party/index.vue'),
        name: Routes.SettingsThirdParty,
        meta: {
          title: '第三方应用设置',
          permissions: [
            PermissionConst.Group.DpBackend.Configs.GetWeCom,
            PermissionConst.Group.DpBackend.Configs.GetOceanengine,
            PermissionConst.Group.DpBackend.Configs.GetWebCall,
            PermissionConst.Group.DpBackend.Configs.GetWeChatApplet
          ]
        }
      }
      // {
      //   path: Routes.SettingsDictionary.replace(Routes.Settings.concat('/'), ''),
      //   component: () => import('@/views/settings/dictionary/index.vue'),
      //   name: Routes.SettingsDictionary,
      //   meta: {
      //     title: '字典'
      //   }
      // },
      // {
      //   path: Routes.SettingsAgreement.replace(Routes.Settings.concat('/'), ''),
      //   component: () => import('@/views/settings/agreement/index.vue'),
      //   name: Routes.SettingsAgreement,
      //   meta: {
      //     title: '协议'
      //   }
      // },
      // {
      //   path: Routes.SettingsAudit.replace(Routes.Settings.concat('/'), ''),
      //   component: () => import('@/views/settings/audit/index.vue'),
      //   name: Routes.SettingsAudit,
      //   meta: {
      //     title: '事件审计'
      //   }
      // },
      // {
      //   path: Routes.SettingsRoles.replace(Routes.Settings.concat('/'), ''),
      //   component: () => import('@/views/settings/roles/index.vue'),
      //   name: Routes.SettingsRoles,
      //   meta: {
      //     title: '角色'
      //   }
      // },
      // {
      //   path: Routes.SettingsSystem.replace(Routes.Settings.concat('/'), ''),
      //   component: () => import('@/views/settings/system/index.vue'),
      //   name: Routes.SettingsSystem,
      //   meta: {
      //     title: '系统设置'
      //   }
      // }
    ]
  }
]
export default new Router({
  // mode: 'history', // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})
