/**
 * @Author: siyukok
 * @Date: 2022/3/1 4:58 PM
 * @Version 1.0
 */
import request from '@/utils/request'
import ApiConst from '@/constant/api'
import { IContactData, IFilterData } from '@/constant/models'
import DateUtil from '@/utils/date'

export default class CustomerApi {
  static createClue(
    source: string,
    customerId: string,
    customerName: string,
    productId: string,
    remark: string,
    createAndWaitAllocate: boolean,
    contactList: IContactData[] = [],
    channelId?: string
  ) {
    const contactWayDictIds: string[] = contactList.map(contact => contact.dictId)
    const contactWays: string[] = contactList.map(contact => contact.value)
    const data = {
      source: source,
      customerName,
      productId: productId,
      remark: remark,
      createAndWaitAllocate: createAndWaitAllocate,
      contactWayDictIds: contactWayDictIds,
      contactWays: contactWays
    }
    if (customerId) {
      Object.defineProperty(data, 'customerId', {
        value: customerId,
        enumerable: true
      })
    }
    if (channelId) {
      Object.defineProperty(data, 'channelId', {
        value: channelId,
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CLUE,
      method: 'post',
      data: data
    })
  }

  static getClues({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.productIds?.forEach((id, index) => {
      Object.defineProperty(params, `ProductIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCreatorIds?.forEach((id, index) => {
      Object.defineProperty(params, `CreatorIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCurrentOwnerIds?.forEach((id, index) => {
      Object.defineProperty(params, `CurrentOwnerIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueSourceTypes?.forEach((type, index) => {
      Object.defineProperty(params, `SourceTypes[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    filter?.clueStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `ClueStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    if (filter?.filter) {
      Object.defineProperty(params, 'CustomerNameOrMobile', {
        value: filter.filter,
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CLUE,
      method: 'get',
      params: params
    })
  }

  static getCustomerClues({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.productIds?.forEach((id, index) => {
      Object.defineProperty(params, `ProductIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCreatorIds?.forEach((id, index) => {
      Object.defineProperty(params, `CreatorIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCurrentOwnerIds?.forEach((id, index) => {
      Object.defineProperty(params, `CurrentOwnerIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueSourceTypes?.forEach((type, index) => {
      Object.defineProperty(params, `SourceTypes[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    filter?.clueStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `ClueStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CLUE_CUSTOMER,
      method: 'get',
      params: params
    })
  }

  static getWaitClues({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.productIds?.forEach((id, index) => {
      Object.defineProperty(params, `ProductIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCreatorIds?.forEach((id, index) => {
      Object.defineProperty(params, `CreatorIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCurrentOwnerIds?.forEach((id, index) => {
      Object.defineProperty(params, `CurrentOwnerIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueSourceTypes?.forEach((type, index) => {
      Object.defineProperty(params, `SourceTypes[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    if (filter?.filter) {
      Object.defineProperty(params, 'CustomerNameOrMobile', {
        value: filter.filter,
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CLUE_WAIT,
      method: 'get',
      params: params
    })
  }

  static getMyClues({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.productIds?.forEach((id, index) => {
      Object.defineProperty(params, `ProductIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCreatorIds?.forEach((id, index) => {
      Object.defineProperty(params, `CreatorIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCurrentOwnerIds?.forEach((id, index) => {
      Object.defineProperty(params, `CurrentOwnerIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueSourceTypes?.forEach((type, index) => {
      Object.defineProperty(params, `SourceTypes[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    filter?.clueStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `ClueStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    if (filter?.filter) {
      Object.defineProperty(params, 'CustomerNameOrMobile', {
        value: filter.filter,
        enumerable: true
      })
    }
    return request({
      url: ApiConst.MY_CLUES,
      method: 'get',
      params: params
    })
  }

  static getCluesCreatedByme({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.productIds?.forEach((id, index) => {
      Object.defineProperty(params, `ProductIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCreatorIds?.forEach((id, index) => {
      Object.defineProperty(params, `CreatorIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueCurrentOwnerIds?.forEach((id, index) => {
      Object.defineProperty(params, `CurrentOwnerIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.clueSourceTypes?.forEach((type, index) => {
      Object.defineProperty(params, `SourceTypes[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    filter?.clueStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `ClueStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    if (filter?.filter) {
      Object.defineProperty(params, 'CustomerNameOrMobile', {
        value: filter.filter,
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CLUES_CREATED_BY_ME,
      method: 'get',
      params: params
    })
  }

  static getClueDetail = (clueId: string) =>
    request({
      url: ApiConst.CLUE_DETAILS(clueId),
      method: 'get'
    })

  static lookUpClue = (clueId: string, mobile: string) =>
    request({
      url: ApiConst.CLUE_LOOK_UP(clueId),
      method: 'post',
      data: {
        mobile
      }
    })

  static editClueRemarks = (clueId: string, remark: string) =>
    request({
      url: ApiConst.CLUE_REMARK_EDIT(clueId),
      method: 'post',
      data: {
        remark
      }
    })

  static followClue(
    clueId: string,
    followedAt: Date,
    // 跟进方式
    followDictId: string,
    // 跟进内容
    remark: string
  ) {
    return request({
      url: ApiConst.CLUE_FOLLOW(clueId),
      method: 'post',
      data: {
        followedAt: DateUtil.dateFormat(followedAt),
        followDictId,
        remark
      }
    })
  }

  static allocateClue(
    clueIds: string[],
    userId: string
  ) {
    return request({
      url: ApiConst.CLUE_ALLOCATE,
      method: 'post',
      data: {
        clueIds,
        userId
      }
    })
  }

  static invalidClue(
    clueId: string,
    invalidDictId: string,
    remark: string
  ) {
    return request({
      url: ApiConst.CLUE_INVALID(clueId),
      method: 'post',
      data: {
        invalidDictId,
        remark
      }
    })
  }

  static clueOrderSucceed(
    clueId: string,
    orderId: string,
    orderSucceedDictId: string,
    remark: string
  ) {
    return request({
      url: ApiConst.CLUE_ORDER_SUCCEED(clueId),
      method: 'post',
      data: {
        orderId,
        orderSucceedDictId,
        remark
      }
    })
  }

  static clueOrderFail(
    clueId: string,
    orderFailDictId: string,
    remark: string
  ) {
    return request({
      url: ApiConst.CLUE_ORDER_FAIL(clueId),
      method: 'post',
      data: {
        orderFailDictId,
        remark
      }
    })
  }

  static clueReturn(
    clueId: string,
    returnDictId: string,
    remark: string
  ) {
    return request({
      url: ApiConst.CLUE_RETURN(clueId),
      method: 'post',
      data: {
        returnDictId,
        remark
      }
    })
  }

  static clueSetTag(
    clueId: string,
    tagIds: string[]
  ) {
    return request({
      url: ApiConst.CLUE_SET_TAG(clueId),
      method: 'post',
      data: tagIds
    })
  }

  static customerList({
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      Filter: filter?.filter
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.customerIdentityIds?.forEach((id, index) => {
      Object.defineProperty(params, `CustomerIdentityIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    return request({
      url: ApiConst.CUSTOMER,
      method: 'get',
      params: params
    })
  }

  static customerDetail = (customerId: string) =>
    request({
      url: ApiConst.CUSTOMER_DETAIL(customerId),
      method: 'get'
    })

  static customerSearch = (keyword: string) =>
    request({
      url: ApiConst.CUSTOMER_SEARCH,
      method: 'get',
      params: {
        Filter: keyword
      }
    })

  static editCustomerName = (customerId: string, name: string) =>
    request({
      url: ApiConst.CUSTOMER_EDIT_NAME(customerId),
      method: 'post',
      data: {
        name
      }
    })

  static editCustomerNickname = (customerId: string, nick: string) =>
    request({
      url: ApiConst.CUSTOMER_EDIT_NICK(customerId),
      method: 'post',
      data: {
        nick
      }
    })

  static editCustomerBirthday = (customerId: string, birthday: Date) =>
    request({
      url: ApiConst.CUSTOMER_EDIT_BIRTHDAY(customerId),
      method: 'post',
      data: {
        birthday: DateUtil.dateFormat(birthday)
      }
    })

  static editCustomerGender = (customerId: string, gender: string) =>
    request({
      url: ApiConst.CUSTOMER_EDIT_GENDER(customerId),
      method: 'post',
      data: {
        gender
      }
    })

  static editCustomerRemark = (customerId: string, remark: string) =>
    request({
      url: ApiConst.CUSTOMER_EDIT_REMARK(customerId),
      method: 'post',
      data: {
        remark
      }
    })

  static editCustomerContact(customerId: string, contactList: IContactData[] = []) {
    const contactWayDictIds: string[] = contactList.map(contact => contact.dictId)
    const contactWays: string[] = contactList.map(contact => contact.value)
    return request({
      url: ApiConst.CUSTOMER_EDIT_CONTACT_WAY(customerId),
      method: 'post',
      data: {
        contactWayDictIds: contactWayDictIds,
        contactWays: contactWays
      }
    })
  }

  static addCustomerContact(customerId: string, contactList: IContactData[] = []) {
    const contactWayDictIds: string[] = contactList.map(contact => contact.dictId)
    const contactWays: string[] = contactList.map(contact => contact.value)
    return request({
      url: ApiConst.CUSTOMER_ADD_CONTACT_WAY(customerId),
      method: 'post',
      data: {
        contactWayDictIds: contactWayDictIds,
        contactWays: contactWays
      }
    })
  }

  static identityList = () =>
    request({
      url: ApiConst.CUSTOM_IDENTITY,
      method: 'get'
    })

  static createIdentity = (name: string, sort: number, headImage: string, {
    cardCover = '',
    icon = '',
    rights = []
  }) =>
    request({
      url: ApiConst.CUSTOM_IDENTITY,
      method: 'post',
      data: {
        name,
        sort,
        headImage,
        cardCover,
        icon,
        rights
      }
    })

  static identityInfo = (id: string) =>
    request({
      url: ApiConst.CUSTOM_IDENTITY_INFO(id),
      method: 'get'
    })

  static identityEdit = (id: string, name: string, sort: number, headImage: string, {
    cardCover = '',
    icon = '',
    rights = []
  }) =>
    request({
      url: ApiConst.CUSTOM_IDENTITY_EDIT(id),
      method: 'post',
      data: {
        name,
        sort,
        headImage,
        cardCover,
        icon,
        rights
      }
    })

  static identitySwitch = (id: string, enable: boolean) =>
    request({
      url: ApiConst.CUSTOM_IDENTITY_ENABLE(id),
      method: 'post',
      data: {
        enable
      }
    })
}
