/**
 * @Author: siyukok
 * @Date: 2022/3/3 10:24 AM
 * @Version 1.0
 */
export default {
  clue: {
    editRemarkSuccess: '编辑备注成功',
    deleteContactSuccess: '删除联系方式成功',
    editContactSuccess: '编辑联系方式成功',
    addContactSuccess: '添加联系方式成功',
    WaitAllocate: '待分配',
    Following: '跟进中',
    OrderSucceeded: '已成单',
    OrderFailed: '已输单',
    Invalid: '已失效',
    OceanEngine: '巨量引擎',
    WeChatCustomer: '企微客户',
    Customer: '客户',
    selectLoseOrderCause: '请选择输单原因',
    selectSuccessOrderCause: '请选择成单原因',
    selectReturnClueCause: '请选择返还原因',
    selectInvalidClueCause: '请选择失效原因',
    selectFollowWay: '请选择跟进方式',
    selectOwner: '请选择归属人',
    createOrderInvalidClue: '需要线索才能创建订单',
    createOrderInvalidProduct: '需要商品才能创建订单',
    createOrderInvalidCustomer: '需要客户才能创建订单'
  },
  system: {
    appError: '系统异常，请刷新后再试'
  }
}
