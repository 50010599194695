/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-down': {
    width: 10,
    height: 6,
    viewBox: '0 0 10 6',
    data: '<path pid="0" d="M9.062 0L5 4.105.938 0 0 .948 5 6l5-5.052z" _fill="#909399" fill-rule="evenodd"/>'
  }
})
