/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendSmartContractsAddSmartContractDto } from '../models';
import { DpBackendSmartContractsEditSmartContractDto } from '../models';
import { DpBackendSmartContractsSmartContractDto } from '../models';
import { DpBackendSmartContractsSmartContractItemDto } from '../models';
import { VoloAbpApplicationDtosPagedResultDto1DpBackendSmartContractsSmartContractDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * SmartContractApi - axios parameter creator
 * @export
 */
export const SmartContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {Array&lt;string&gt;} [codes]
         * @param {Array&lt;string&gt;} [productIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractGet: async (filter?: string, codes?: Array<string>, productIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/smart-contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (codes) {
              codes?.forEach((code, index) => {
                localVarQueryParameter[`Codes[${index}]`] = code;
              })
            }

            if (productIds) {
              productIds?.forEach((id, index) => {
                localVarQueryParameter[`ProductIds[${index}]`] = id;
              })
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdDelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppSmartContractIdDelPost.');
            }
            const localVarPath = `/api/app/smart-contract/{id}/del`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendSmartContractsEditSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdEditPost: async (id: string, body?: DpBackendSmartContractsEditSmartContractDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppSmartContractIdEditPost.');
            }
            const localVarPath = `/api/app/smart-contract/{id}/edit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiAppSmartContractIdGet.');
            }
            const localVarPath = `/api/app/smart-contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DpBackendSmartContractsAddSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractPost: async (body?: DpBackendSmartContractsAddSmartContractDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/smart-contract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractSelectItemsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/smart-contract/select-items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmartContractApi - functional programming interface
 * @export
 */
export const SmartContractApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {Array&lt;string&gt;} [codes]
         * @param {Array&lt;string&gt;} [productIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractGet(filter?: string, codes?: Array<string>, productIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendSmartContractsSmartContractDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractGet(filter, codes, productIds, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractIdDelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractIdDelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendSmartContractsEditSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractIdEditPost(id: string, body?: DpBackendSmartContractsEditSmartContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractIdEditPost(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendSmartContractsSmartContractDto>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {DpBackendSmartContractsAddSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractPost(body?: DpBackendSmartContractsAddSmartContractDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppSmartContractSelectItemsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DpBackendSmartContractsSmartContractItemDto>>> {
            const localVarAxiosArgs = await SmartContractApiAxiosParamCreator(configuration).apiAppSmartContractSelectItemsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SmartContractApi - factory interface
 * @export
 */
export const SmartContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {string} [filter]
         * @param {Array&lt;string&gt;} [codes]
         * @param {Array&lt;string&gt;} [productIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractGet(filter?: string, codes?: Array<string>, productIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendSmartContractsSmartContractDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull> {
            return SmartContractApiFp(configuration).apiAppSmartContractGet(filter, codes, productIds, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdDelPost(id: string, options?: any): AxiosPromise<boolean> {
            return SmartContractApiFp(configuration).apiAppSmartContractIdDelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {DpBackendSmartContractsEditSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdEditPost(id: string, body?: DpBackendSmartContractsEditSmartContractDto, options?: any): AxiosPromise<string> {
            return SmartContractApiFp(configuration).apiAppSmartContractIdEditPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractIdGet(id: string, options?: any): AxiosPromise<DpBackendSmartContractsSmartContractDto> {
            return SmartContractApiFp(configuration).apiAppSmartContractIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DpBackendSmartContractsAddSmartContractDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractPost(body?: DpBackendSmartContractsAddSmartContractDto, options?: any): AxiosPromise<string> {
            return SmartContractApiFp(configuration).apiAppSmartContractPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppSmartContractSelectItemsGet(options?: any): AxiosPromise<Array<DpBackendSmartContractsSmartContractItemDto>> {
            return SmartContractApiFp(configuration).apiAppSmartContractSelectItemsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SmartContractApi - object-oriented interface
 * @export
 * @class SmartContractApi
 * @extends {BaseAPI}
 */
export class SmartContractApi extends BaseAPI {
    /**
     *
     * @param {string} [filter]
     * @param {Array&lt;string&gt;} [codes]
     * @param {Array&lt;string&gt;} [productIds]
     * @param {string} [sorting]
     * @param {number} [skipCount]
     * @param {number} [maxResultCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractGet(filter?: string, codes?: Array<string>, productIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractGet(filter, codes, productIds, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractIdDelPost(id: string, options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractIdDelPost(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {DpBackendSmartContractsEditSmartContractDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractIdEditPost(id: string, body?: DpBackendSmartContractsEditSmartContractDto, options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractIdEditPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractIdGet(id: string, options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {DpBackendSmartContractsAddSmartContractDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractPost(body?: DpBackendSmartContractsAddSmartContractDto, options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartContractApi
     */
    public apiAppSmartContractSelectItemsGet(options?: any) {
        return SmartContractApiFp(this.configuration).apiAppSmartContractSelectItemsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
