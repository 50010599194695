
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IDictData } from '@/api/types'
import ArrayUtil from '@/utils/array'
import AppService from '@/services/app'
import { IContactData } from '@/constant/models'
import Constant from '@/constant/constant'
import PermissionConst from '@/constant/permissions'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'ContactInput'
})
export default class extends Vue {
  @Prop({ required: true }) private contact!: IContactData
  private contactMethods: IDictData[] = []
  private localContact !: IContactData

  @Watch('contact', { immediate: true })
  private onContactChange(contact: IContactData) {
    this.localContact = contact
  }

  created() {
    this.contactMethods = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
  }

  private get hasEditPermission() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.EditContactWay)
  }

  private deleteContact() {
    this.$emit('delete')
  }

  private onInputValueChange() {
    const method: IDictData | undefined = this.contactMethods.find(dict => {
      return dict.id === this.localContact.dictId
    })
    if (!method) {
      return
    }
    this.localContact.methodName = method?.name
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const validator: Function = Constant.formRules[method.name]
    validator(null, this.contact.value, (response: any) => {
      this.localContact.error = response?.message
      this.onContactUpdate()
    })
  }

  private onContactUpdate() {
    this.$emit('update', this.localContact)
  }
}
