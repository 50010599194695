/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'analyze': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#606266" fill-rule="nonzero"><path pid="0" d="M10 2a8 8 0 110 16 8 8 0 010-16zm0 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"/><path pid="1" d="M13.591 7.458l1.038 1.084-3.597 3.445a.75.75 0 01-.985.046l-.082-.076-1.382-1.48L6.53 12.53l-1.06-1.06 2.6-2.602a.75.75 0 01.996-.058l.083.077 1.393 1.491 3.05-2.92z"/></g>'
  }
})
