import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import Routes from '@/constant/routes'
import UserService from '@/services/user'
import { PermissionModule } from '@/store/modules/permission'
import { AppModule } from '@/store/modules/app'
import AppService from '@/services/app'

NProgress.configure({ showSpinner: false })

const whiteList = [Routes.Login, Routes.AuthorizeCallback]

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()

  // Determine whether the user has logged in
  if (UserModule.oauth.accessToken) {
    if (to.path === Routes.Login) {
      // If is logged in, redirect to the home page
      next({ path: Routes.Home })
      NProgress.done()
    } else {
      // Check dict
      if (AppModule.dictGroups.length === 0) {
        await AppService.refreshAppDictGroups()
      }
      // Check whether the user has obtained his permissions
      if (UserModule.permissions.length === 0) {
        try {
          // Get user info, including permissions
          await UserModule.GetUserInfo()
          const permissions = UserModule.permissions
          // Generate accessible routes map based on role
          PermissionModule.GenerateRoutes(permissions)
          // Dynamically add accessible routes
          PermissionModule.dynamicRoutes.forEach(route => {
            router.addRoute(route)
          })
          // Set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          })
        } catch (err) {
          // Remove token and redirect to login page
          UserModule.ResetToken()
          Message.error(err || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      } else {
        next()
      }
    }
    // 使用refresh_token获取新token
    await UserService.checkAndRefreshToken()
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to.meta.title
})
