/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendConfigsCheckModuleDto } from '../models';
import { DpBackendConfigsConfigValueOceanengine } from '../models';
import { DpBackendConfigsConfigValueWeChatApplet } from '../models';
import { DpBackendConfigsConfigValueWeCom } from '../models';
import { DpBackendConfigsConfigValueWebCall } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckOceanenginePost: async (body?: DpBackendConfigsConfigValueOceanengine, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/check-oceanengine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWeChatAppletPost: async (body?: DpBackendConfigsConfigValueWeChatApplet, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/check-we-chat-applet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWeComPost: async (body?: DpBackendConfigsConfigValueWeCom, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/check-we-com`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWebCallPost: async (body?: DpBackendConfigsConfigValueWebCall, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/check-web-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigOceanengineGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/oceanengine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetOceanenginePost: async (body?: DpBackendConfigsConfigValueOceanengine, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/set-oceanengine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWeChatAppletPost: async (body?: DpBackendConfigsConfigValueWeChatApplet, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/set-we-chat-applet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWeComPost: async (body?: DpBackendConfigsConfigValueWeCom, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/set-we-com`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWebCallPost: async (body?: DpBackendConfigsConfigValueWebCall, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/set-web-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWeChatAppletGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/we-chat-applet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWeComGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/we-com`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWebCallGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/config/web-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigCheckOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsCheckModuleDto>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigCheckOceanenginePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigCheckWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsCheckModuleDto>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigCheckWeChatAppletPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigCheckWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsCheckModuleDto>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigCheckWeComPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigCheckWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsCheckModuleDto>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigCheckWebCallPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean;}>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigOceanengineGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsConfigValueOceanengine>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigOceanengineGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigSetOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigSetOceanenginePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigSetWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigSetWeChatAppletPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigSetWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigSetWeComPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigSetWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigSetWebCallPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigWeChatAppletGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsConfigValueWeChatApplet>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigWeChatAppletGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigWeComGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsConfigValueWeCom>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigWeComGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppConfigWebCallGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendConfigsConfigValueWebCall>> {
            const localVarAxiosArgs = await ConfigApiAxiosParamCreator(configuration).apiAppConfigWebCallGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any): AxiosPromise<DpBackendConfigsCheckModuleDto> {
            return ConfigApiFp(configuration).apiAppConfigCheckOceanenginePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any): AxiosPromise<DpBackendConfigsCheckModuleDto> {
            return ConfigApiFp(configuration).apiAppConfigCheckWeChatAppletPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any): AxiosPromise<DpBackendConfigsCheckModuleDto> {
            return ConfigApiFp(configuration).apiAppConfigCheckWeComPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigCheckWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any): AxiosPromise<DpBackendConfigsCheckModuleDto> {
            return ConfigApiFp(configuration).apiAppConfigCheckWebCallPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigGet(options?: any): AxiosPromise<{ [key: string]: boolean;}> {
            return ConfigApiFp(configuration).apiAppConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigOceanengineGet(options?: any): AxiosPromise<DpBackendConfigsConfigValueOceanengine> {
            return ConfigApiFp(configuration).apiAppConfigOceanengineGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueOceanengine} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any): AxiosPromise<boolean> {
            return ConfigApiFp(configuration).apiAppConfigSetOceanenginePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any): AxiosPromise<boolean> {
            return ConfigApiFp(configuration).apiAppConfigSetWeChatAppletPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWeCom} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any): AxiosPromise<boolean> {
            return ConfigApiFp(configuration).apiAppConfigSetWeComPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DpBackendConfigsConfigValueWebCall} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigSetWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any): AxiosPromise<boolean> {
            return ConfigApiFp(configuration).apiAppConfigSetWebCallPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWeChatAppletGet(options?: any): AxiosPromise<DpBackendConfigsConfigValueWeChatApplet> {
            return ConfigApiFp(configuration).apiAppConfigWeChatAppletGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWeComGet(options?: any): AxiosPromise<DpBackendConfigsConfigValueWeCom> {
            return ConfigApiFp(configuration).apiAppConfigWeComGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppConfigWebCallGet(options?: any): AxiosPromise<DpBackendConfigsConfigValueWebCall> {
            return ConfigApiFp(configuration).apiAppConfigWebCallGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @param {DpBackendConfigsConfigValueOceanengine} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigCheckOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigCheckOceanenginePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigCheckWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigCheckWeChatAppletPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWeCom} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigCheckWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigCheckWeComPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWebCall} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigCheckWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigCheckWebCallPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigGet(options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigOceanengineGet(options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigOceanengineGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueOceanengine} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigSetOceanenginePost(body?: DpBackendConfigsConfigValueOceanengine, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigSetOceanenginePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWeChatApplet} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigSetWeChatAppletPost(body?: DpBackendConfigsConfigValueWeChatApplet, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigSetWeChatAppletPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWeCom} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigSetWeComPost(body?: DpBackendConfigsConfigValueWeCom, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigSetWeComPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {DpBackendConfigsConfigValueWebCall} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigSetWebCallPost(body?: DpBackendConfigsConfigValueWebCall, options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigSetWebCallPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigWeChatAppletGet(options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigWeChatAppletGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigWeComGet(options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigWeComGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public apiAppConfigWebCallGet(options?: any) {
        return ConfigApiFp(this.configuration).apiAppConfigWebCallGet(options).then((request) => request(this.axios, this.basePath));
    }
}
