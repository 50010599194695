/**
 * @Author: siyukok
 * @Date: 2022/1/17 4:23 PM
 * @Description something...
 */
export default class TextUtil {
  static randomString(len = 32) {
    const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    /** 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1**/
    const maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
  }

  static isEmpty(text: any): boolean {
    return text === null || text === undefined || String(text).length === 0
  }

  static isNotEmpty(text: any): boolean {
    return !this.isEmpty(text)
  }

  static filterString(text: any) {
    if (!text && text !== 0) {
      return ''
    }
    return String(text)
  }

  static translateEmptyToUndef(str: string) {
    if (str === '') {
      return undefined
    }
    return str
  }

  static subEnds(str: string, length = 6) {
    return str.substring(str.length - length, str.length)
  }

  static getUuidCode() {
    // 64长度
    const len = 64
    // 16进制
    let radix = 16
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    const uuid = []
    let i
    radix = radix || chars.length
    if (len) {
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | Math.random() * radix]
      }
    } else {
      let r
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join('')
  }
}
