import request from '@/utils/request'
import ApiConst from '@/constant/api'
import * as qs from 'qs'
import Constant from '@/constant/constant'

export default class UserApi {
  static logout = () =>
    request({
      url: ApiConst.LOGOUT,
      method: 'get'
    })

  private static connectToken = (data: any) =>
    request({
      url: ApiConst.CONNECT_TOKEN,
      method: 'post',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data)
    })

  static getTokenByCode = (code: string) =>
    UserApi.connectToken({
      grant_type: Constant.GRANT_TYPES.Code,
      client_id: Constant.CLIENT_ID,
      code: code,
      redirect_uri: `${Constant.APP_HOST}#/authorize/callback`
    })

  static getTokenByPassword = (username: string, password: string) =>
    UserApi.connectToken({
      grant_type: Constant.GRANT_TYPES.Password,
      client_id: Constant.CLIENT_ID,
      username: username,
      password: password,
      redirect_uri: `${Constant.APP_HOST}#/authorize/callback`
    })

  static getTokenByRefreshToken = (refreshToken: string) =>
    UserApi.connectToken({
      grant_type: Constant.GRANT_TYPES.RefreshToken,
      client_id: Constant.CLIENT_ID,
      refresh_token: refreshToken,
      redirect_uri: `${Constant.APP_HOST}#/authorize/callback`
    })

  static getProfile = () =>
    request({
      url: ApiConst.MY_PROFILE,
      method: 'get'
    })
}
