/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'customer': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M14 15.188a5.5 5.5 0 010-11 5.5 5.5 0 110 11zm0-2.063a3.438 3.438 0 100-6.875 3.438 3.438 0 100 6.875zm-5.5 3.438h11a4.125 4.125 0 014.125 4.125v1.375a2.75 2.75 0 01-2.75 2.75H7.125a2.75 2.75 0 01-2.75-2.75v-1.375A4.125 4.125 0 018.5 16.563zm0 2.062c-1.14 0-2.062.923-2.062 2.063v1.375c0 .38.307.687.687.687h13.75c.38 0 .688-.308.688-.687v-1.375c0-1.14-.924-2.063-2.063-2.063h-11z" _fill="#444" fill-rule="nonzero"/>'
  }
})
