import TextUtil from '@/utils/text'
import NumberUtil from '@/utils/number'
import { KEY_ACCESS_REFRESH_TOKEN, KEY_ACCESS_TOKEN, KEY_ACCESS_TOKEN_EXPIRE, KEY_CORP_ID } from '@/storages/index'

/**
 * @Author: siyukok
 * @Date: 2022/2/10 2:20 PM
 * @Version 1.0
 */
export default class UserStorage {
  static get accessToken() {
    return TextUtil.filterString(localStorage.getItem(KEY_ACCESS_TOKEN))
  }

  static set accessToken(token) {
    localStorage.setItem(KEY_ACCESS_TOKEN, TextUtil.filterString(token))
  }

  static removeToken() {
    localStorage.removeItem(KEY_ACCESS_TOKEN)
  }

  static get expireAt() {
    return NumberUtil.parseInt(localStorage.getItem(KEY_ACCESS_TOKEN_EXPIRE))
  }

  static set expireAt(expireAt) {
    localStorage.setItem(KEY_ACCESS_TOKEN_EXPIRE, String(NumberUtil.parseInt(expireAt)))
  }

  static removeExpireAt() {
    localStorage.removeItem(KEY_ACCESS_TOKEN_EXPIRE)
  }

  static get refreshToken() {
    return TextUtil.filterString(localStorage.getItem(KEY_ACCESS_REFRESH_TOKEN))
  }

  static set refreshToken(token) {
    localStorage.setItem(KEY_ACCESS_REFRESH_TOKEN, TextUtil.filterString(token))
  }

  static removeRefreshToken() {
    localStorage.removeItem(KEY_ACCESS_REFRESH_TOKEN)
  }

  static get corpId() {
    return TextUtil.filterString(localStorage.getItem(KEY_CORP_ID))
  }

  static set corpId(corpId) {
    localStorage.setItem(KEY_CORP_ID, TextUtil.filterString(corpId))
  }
}
