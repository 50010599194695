/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'operate': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#606266" fill-rule="nonzero"><path pid="0" d="M6.436 8.742H7.92v2.438H6.436zM9.257 7.483h1.485v3.697H9.257zM12.08 6.303h1.484v4.877H12.08zM5.322 15.427h9.43V17h-9.43z"/><path pid="1" d="M17.5 14.011h-15V3h15v11.011zM3.985 12.438h12.03V4.573H3.985v7.865z"/></g>'
  }
})
