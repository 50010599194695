/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DpBackendWebCallsPostCallDto } from '../models';
import { DpBackendWebCallsPostCallbackDto } from '../models';
import { DpBackendWebCallsWebCallInfo } from '../models';
import { VoloAbpApplicationDtosPagedResultDto1DpBackendWebCallsWebCallRecordDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
/**
 * WebCallApi - axios parameter creator
 * @export
 */
export const WebCallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {DpBackendWebCallsPostCallDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallCallPost: async (body?: DpBackendWebCallsPostCallDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/web-call/call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DpBackendWebCallsPostCallbackDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallCallbackPost: async (body?: DpBackendWebCallsPostCallbackDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/web-call/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallGet: async (filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/web-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['StartAt'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString() :
                    startAt;
            }

            if (endAt !== undefined) {
                localVarQueryParameter['EndAt'] = (endAt as any instanceof Date) ?
                    (endAt as any).toISOString() :
                    endAt;
            }

            if (abpUserIds) {
              abpUserIds?.forEach((id, index) => {
                localVarQueryParameter[`AbpUserIds[${index}]`] = id;
              })
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallMeGet: async (filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/app/web-call/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['StartAt'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString() :
                    startAt;
            }

            if (endAt !== undefined) {
                localVarQueryParameter['EndAt'] = (endAt as any instanceof Date) ?
                    (endAt as any).toISOString() :
                    endAt;
            }

            if (abpUserIds) {
              abpUserIds?.forEach((id, index) => {
                localVarQueryParameter[`AbpUserIds[${index}]`] = id;
              })
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebCallApi - functional programming interface
 * @export
 */
export const WebCallApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {DpBackendWebCallsPostCallDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppWebCallCallPost(body?: DpBackendWebCallsPostCallDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DpBackendWebCallsWebCallInfo>> {
            const localVarAxiosArgs = await WebCallApiAxiosParamCreator(configuration).apiAppWebCallCallPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {DpBackendWebCallsPostCallbackDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppWebCallCallbackPost(body?: DpBackendWebCallsPostCallbackDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await WebCallApiAxiosParamCreator(configuration).apiAppWebCallCallbackPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppWebCallGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendWebCallsWebCallRecordDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await WebCallApiAxiosParamCreator(configuration).apiAppWebCallGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppWebCallMeGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendWebCallsWebCallRecordDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await WebCallApiAxiosParamCreator(configuration).apiAppWebCallMeGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WebCallApi - factory interface
 * @export
 */
export const WebCallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {DpBackendWebCallsPostCallDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallCallPost(body?: DpBackendWebCallsPostCallDto, options?: any): AxiosPromise<DpBackendWebCallsWebCallInfo> {
            return WebCallApiFp(configuration).apiAppWebCallCallPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DpBackendWebCallsPostCallbackDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallCallbackPost(body?: DpBackendWebCallsPostCallbackDto, options?: any): AxiosPromise<string> {
            return WebCallApiFp(configuration).apiAppWebCallCallbackPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendWebCallsWebCallRecordDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull> {
            return WebCallApiFp(configuration).apiAppWebCallGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [filter]
         * @param {string} [startAt]
         * @param {string} [endAt]
         * @param {Array&lt;string&gt;} [abpUserIds]
         * @param {string} [sorting]
         * @param {number} [skipCount]
         * @param {number} [maxResultCount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppWebCallMeGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1DpBackendWebCallsWebCallRecordDtoDpBackendApplicationContractsVersion1000CultureneutralPublicKeyTokennull> {
            return WebCallApiFp(configuration).apiAppWebCallMeGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebCallApi - object-oriented interface
 * @export
 * @class WebCallApi
 * @extends {BaseAPI}
 */
export class WebCallApi extends BaseAPI {
    /**
     *
     * @param {DpBackendWebCallsPostCallDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebCallApi
     */
    public apiAppWebCallCallPost(body?: DpBackendWebCallsPostCallDto, options?: any) {
        return WebCallApiFp(this.configuration).apiAppWebCallCallPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {DpBackendWebCallsPostCallbackDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebCallApi
     */
    public apiAppWebCallCallbackPost(body?: DpBackendWebCallsPostCallbackDto, options?: any) {
        return WebCallApiFp(this.configuration).apiAppWebCallCallbackPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [filter]
     * @param {string} [startAt]
     * @param {string} [endAt]
     * @param {Array&lt;string&gt;} [abpUserIds]
     * @param {string} [sorting]
     * @param {number} [skipCount]
     * @param {number} [maxResultCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebCallApi
     */
    public apiAppWebCallGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return WebCallApiFp(this.configuration).apiAppWebCallGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [filter]
     * @param {string} [startAt]
     * @param {string} [endAt]
     * @param {Array&lt;string&gt;} [abpUserIds]
     * @param {string} [sorting]
     * @param {number} [skipCount]
     * @param {number} [maxResultCount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebCallApi
     */
    public apiAppWebCallMeGet(filter?: string, startAt?: string, endAt?: string, abpUserIds?: Array<string>, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return WebCallApiFp(this.configuration).apiAppWebCallMeGet(filter, startAt, endAt, abpUserIds, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
}
