/**
 * @Author: siyukok
 * @Date: 2022/3/24 5:42 PM
 * @Version 1.0
 */
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ResetMixin'
})
export default class extends Vue {
  // todo refactor AddContact无效
  protected originData: object = {}

  mounted() {
    Object.assign(this.originData, this.$data)
  }

  resetData() {
    Object.assign(this.$data, this.originData)
  }
}
