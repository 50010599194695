/**
 * @Author: siyukok
 * @Date: 2022/2/21 10:53 AM
 * @Version 1.0
 */
import request from '@/utils/request'
import ApiConst from '@/constant/api'
import { IPermissionData } from '@/api/types'
import { TagType } from '@/constant/constant'

export default class SystemApi {
  static getRolePermission = (roleName: string) =>
    request({
      url: `${ApiConst.PERMISSION_MANAGEMENT}?providerName=R&providerKey=${roleName}`,
      method: 'get'
    })

  static putRolePermission = (permissions: IPermissionData[], providerKey: string) =>
    request({
      url: `${ApiConst.PERMISSION_MANAGEMENT}?providerName=R&providerKey=${providerKey}`,
      method: 'put',
      params: {
        permissions
      }
    })

  static getUserPermission = (abpUserId: string) =>
    request({
      url: `${ApiConst.PERMISSION_MANAGEMENT}?providerName=U&providerKey=${abpUserId}`,
      method: 'get'
    })

  static putUserPermission = (permissions: IPermissionData[], abpUserId: string) =>
    request({
      url: `${ApiConst.PERMISSION_MANAGEMENT}?providerName=U&providerKey=${abpUserId}`,
      method: 'put',
      params: {
        permissions
      }
    })

  static editTag = (id: string, tagName: string) =>
    request({
      url: `${ApiConst.TAG_EDIT(id)}`,
      method: 'post',
      data: {
        tagName
      }
    })

  static deleteTag = (id: string) =>
    request({
      url: `${ApiConst.TAG_DELETE(id)}`,
      method: 'post'
    })

  static setTag = (tagType: TagType, ids: string[], tagIds: string[]) =>
    request({
      url: ApiConst.TAG_SET,
      method: 'post',
      data: {
        tagType: tagType.toString(),
        ids,
        tagIds
      }
    })

  static getTagGroup = () =>
    request({
      url: ApiConst.TAG_GROUP,
      method: 'get'
    })

  static getAllTagGroup = () =>
    request({
      url: ApiConst.TAG_GROUP_ALL,
      method: 'get'
    })

  static getAllTagGroupRead = () =>
    request({
      url: ApiConst.TAG_GROUP_ALL_READ,
      method: 'get'
    })

  static postTagGroup = (groupName: string) =>
    request({
      url: ApiConst.TAG_GROUP,
      method: 'post',
      data: {
        groupName
      }
    })

  static getTagGroupById = (id: string) =>
    request({
      url: ApiConst.TAG_GROUP_BY_ID(id),
      method: 'get'
    })

  static editTagGroup = (id: string, groupName: string) =>
    request({
      url: ApiConst.TAG_GROUP_EDIT(id),
      method: 'post',
      data: {
        groupName
      }
    })

  static deleteTagGroup = (id: string) =>
    request({
      url: ApiConst.TAG_GROUP_DELETE(id),
      method: 'post'
    })

  static getGroupTags = (id: string) =>
    request({
      url: ApiConst.TAG_GROUP_TAGS(id),
      method: 'get'
    })

  static addTagToGroup = (id: string, tagName: string) =>
    request({
      url: ApiConst.TAG_GROUP_TAGS(id),
      method: 'post',
      data: {
        tagName
      }
    })

  static getDictGroups = () =>
    request({
      url: ApiConst.DICT_GROUPS,
      method: 'get'
    })
}
