import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18n from '@/lang'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import WecomUtil from '@/utils/wecom'
import TextUtil from '@/utils/text'
import ArrayUtil from '@/utils/array'
import Constant, { dateRangePickerOptions } from '@/constant/constant'
import ObjectUtil from '@/utils/object'
import { IEnumData } from '@/constant/models'
// fix Added non-passive event listener to a scroll-blocking 'mousewheel' event.
// Consider marking event handler as 'passive' to make the page more responsive
// about passive more info
// see https://zhuanlan.zhihu.com/p/24555031
// see https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// see https://blog.csdn.net/dj0379/article/details/52883315
import 'default-passive-events'
import NumberUtil from '@/utils/number'
import variables from '@/styles/_variables.scss'
import { UserModule } from '@/store/modules/user'
import PermissionConst from '@/constant/permissions'
import QsUtil from '@/utils/qs'

Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value)
})
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.config.silent = process.env.NODE_ENV === 'production'
Vue.config.productionTip = false
Vue.use((vue) => {
  vue.prototype.$filterString = TextUtil.filterString
  vue.prototype.$subEnds = TextUtil.subEnds
  vue.prototype.$parseFloat = NumberUtil.parseFloat
  vue.prototype.$formatTime = NumberUtil.formatTime
  vue.prototype.$filterArray = ArrayUtil.filter
  vue.prototype.$clueSourceLabel = (source: string) =>
    ObjectUtil.getProperty<IEnumData>(source, Constant.ClueSourceType)?.label
  vue.prototype.$clueStatusLabel = (status: string) =>
    ObjectUtil.getProperty<IEnumData>(status, Constant.ClueStatus)?.label
  vue.prototype.$signStatusLabel = (status: string) =>
    ObjectUtil.getProperty<IEnumData>(status, Constant.SignStatus)?.label
  vue.prototype.$orderStatusLabel = (status: string) =>
    ObjectUtil.getProperty<IEnumData>(status, Constant.OrderStatus)?.label
  vue.prototype.$contractStatusLabel = (status: string) =>
    ObjectUtil.getProperty<IEnumData>(status, Constant.ContractStatus)?.label
  vue.prototype.$orderTypeLabel = (status: string) =>
    ObjectUtil.getProperty<IEnumData>(status, Constant.OrderType)?.label
  vue.prototype.$genderLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.GenderType)?.label
  vue.prototype.$productTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.ProductType)?.label
  vue.prototype.$productStatusLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.ProductStatus)?.label
  vue.prototype.$payTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.PayType)?.label
  vue.prototype.$clueKeyRoleTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.ClueKeyRoleType)?.label
  vue.prototype.$customerKeyRoleTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.CustomerKeyRoleType)?.label
  vue.prototype.$orderKeyRoleTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.OrderKeyRoleType)?.label
  vue.prototype.$wecomSourceTypeLabel = (type: string) =>
    ObjectUtil.getProperty<IEnumData>(type, Constant.WecomSourceType)?.label
  vue.prototype.$dialogWidth = '80%'
  vue.prototype.$smallDialogWidth = '40%'
  vue.prototype.$formLabelWidth = '100px'
  vue.prototype.$largeFormLabelWidth = '160px'
  vue.prototype.$contactLength = 50
  vue.prototype.$dateRangePickerOptions = dateRangePickerOptions
  Vue.prototype.$EventBus = new Vue()
  Vue.prototype.$variables = variables
  Vue.prototype.$permissons = PermissionConst.Group
  // 注册一个全局自定义指令 `v-permission`
  Vue.directive('permission', {
    bind: function(el, binding) {
      if (binding.value && !PermissionConst.hasPermission(UserModule.permissions, binding.value)) {
        el.style.display = 'none'
      }
    }
  })
})

// WecomUtil.getCorpId(TextUtil.filterString(QsUtil.getUrlKey('corpid')))
WecomUtil.init()

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
