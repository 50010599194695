/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'auth': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#606266" fill-rule="nonzero"><path pid="0" d="M10.072 1.5l-2.14 1.22-.73.408-.695.378c-.492.262-.96.501-1.407.718a20.54 20.54 0 01-2.009.86l-.48.172-.06 1.03c-.07 1.357-.075 2.564.032 3.758.117 1.3.358 2.405.763 3.278.878 1.893 2.956 3.355 5.978 4.832l.721.346.338-.161c3.246-1.548 5.443-3.052 6.293-5.038.759-1.774.917-4.275.78-7.095L17.4 5.24l-.501-.162c-1.86-.603-3.732-1.61-5.95-3.015l-.878-.563zM8.704 4.045l1.318-.756.101.065c1.988 1.258 3.707 2.21 5.429 2.868l.357.131.014.314c.092 2.438-.07 4.626-.677 6.042l-.083.18c-.602 1.196-2.066 2.312-4.143 3.416l-.641.331c-.11.056-.222.11-.335.166l-.663-.333c-2.355-1.214-4.004-2.443-4.624-3.78-.32-.69-.527-1.637-.629-2.779l-.036-.478a24.67 24.67 0 01-.017-2.523l.026-.572.192-.075c.466-.19.963-.414 1.492-.67.466-.225.952-.473 1.46-.745l.355-.19.725-.4.379-.212z"/><path pid="1" d="M12.798 7.603l1.112 1.081-4.429 4.047-2.845-2.577 1.1-1.092 1.733 1.57z"/></g>'
  }
})
