/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hamburger': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#606266" fill-rule="evenodd"><path pid="0" d="M4 4h12v2H4zM4 14h12v2H4zM4 9h6v2H4zM15.75 10.167l-2.438 1.625a.201.201 0 01-.312-.168V8.376a.201.201 0 01.312-.168l2.437 1.625a.201.201 0 010 .334z"/></g>'
  }
})
