/**
 * @Author: siyukok
 * @Date: 2022/4/1 7:38 PM
 * @Version 1.0
 */
export default class Events {
  static CREATE_CLUE = 'CREATE_CLUE'
  static ADD_CONTACT = 'ADD_CONTACT'
  static TAG_SET = 'TAG_SET'
  static PAY_ORDER = 'PAY_ORDER'
  static REFUND_ORDER = 'REFUND_ORDER'
  static MARKETING_DATA_UPDATE = 'MARKETING_DATA_UPDATE'
  static MARKETING_FLOW_OCEAN_UPDATE = 'MARKETING_FLOW_OCEAN_UPDATE'
}
