/**
 * @Author: siyukok
 * @Date: 2022/1/18 3:15 PM
 * @Version 1.0
 */
import request from '@/utils/request'
import ApiConst from '@/constant/api'
import DateUtil from '@/utils/date'
import { IFilterData } from '@/constant/models'

export default class WecomApi {
  static getWecomSign = (url: string) =>
    request({
      url: ApiConst.GET_WECOM_SIGN,
      method: 'get',
      params: {
        url
      }
    })

  static getWecomAppSign = (url: string) =>
    request({
      url: ApiConst.GET_WECOM_APP_SIGN,
      method: 'get',
      params: {
        url
      }
    })

  static getDepartment = () =>
    request({
      url: ApiConst.DEPARTMENT,
      method: 'get'
    })

  static getCorpUser = (departmentId: number) =>
    request({
      url: `${ApiConst.CORP_USER}?departmentId=${departmentId}`,
      method: 'get'
    })

  static getCorpUserSearch = () =>
    request({
      url: ApiConst.CORP_USER_SEARCH,
      method: 'get'
    })

  static syncCorpUser = (departmentId = 1) =>
    request({
      url: `${ApiConst.CORP_USER_SYNC(departmentId)}`,
      method: 'post'
    })

  static corpUserInfo = () =>
    request({
      url: ApiConst.CORP_USER_INFO,
      method: 'get'
    })

  static wecomCustomerInfo = (wecomUserId: string) =>
    request({
      url: `${ApiConst.WECOM_CUSTOMER_INFO(wecomUserId)}`,
      method: 'get'
    })

  static wecomCustomerList({
    page = 1,
    pageSize = 20
  }, filter?: IFilterData) {
    const params = {
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      Filter: filter?.filter
    }
    filter?.tagIds?.forEach((id, index) => {
      Object.defineProperty(params, `tagIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    filter?.weComUserIds?.forEach((id, index) => {
      Object.defineProperty(params, `WeComUserIds[${index}]`, {
        value: id,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    return request({
      url: ApiConst.WECOM_CUSTOMER_LIST,
      method: 'get',
      params: params
    })
  }
}
