/* tslint:disable */
/* eslint-disable */
/**
 * DpBackend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { VoloAbpApplicationDtosListResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpApplicationDtosPagedResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull } from '../models';
import { VoloAbpHttpRemoteServiceErrorResponse } from '../models';
import { VoloAbpIdentityIdentityRoleCreateDto } from '../models';
import { VoloAbpIdentityIdentityRoleDto } from '../models';
import { VoloAbpIdentityIdentityRoleUpdateDto } from '../models';
/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesAllGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identity/roles/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesGet: async (filter?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identity/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiIdentityRolesIdDelete.');
            }
            const localVarPath = `/api/identity/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiIdentityRolesIdGet.');
            }
            const localVarPath = `/api/identity/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {VoloAbpIdentityIdentityRoleUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdPut: async (id: string, body?: VoloAbpIdentityIdentityRoleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiIdentityRolesIdPut.');
            }
            const localVarPath = `/api/identity/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VoloAbpIdentityIdentityRoleCreateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesPost: async (body?: VoloAbpIdentityIdentityRoleCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identity/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("oauth2", ["DpBackend"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesAllGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosListResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesAllGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesGet(filter?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpApplicationDtosPagedResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesGet(filter, sorting, skipCount, maxResultCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpIdentityIdentityRoleDto>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {VoloAbpIdentityIdentityRoleUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesIdPut(id: string, body?: VoloAbpIdentityIdentityRoleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpIdentityIdentityRoleDto>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesIdPut(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VoloAbpIdentityIdentityRoleCreateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiIdentityRolesPost(body?: VoloAbpIdentityIdentityRoleCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoloAbpIdentityIdentityRoleDto>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).apiIdentityRolesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesAllGet(options?: any): AxiosPromise<VoloAbpApplicationDtosListResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull> {
            return RoleApiFp(configuration).apiIdentityRolesAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesGet(filter?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<VoloAbpApplicationDtosPagedResultDto1VoloAbpIdentityIdentityRoleDtoVoloAbpIdentityApplicationContractsVersion5110CultureneutralPublicKeyTokennull> {
            return RoleApiFp(configuration).apiIdentityRolesGet(filter, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return RoleApiFp(configuration).apiIdentityRolesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdGet(id: string, options?: any): AxiosPromise<VoloAbpIdentityIdentityRoleDto> {
            return RoleApiFp(configuration).apiIdentityRolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {VoloAbpIdentityIdentityRoleUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesIdPut(id: string, body?: VoloAbpIdentityIdentityRoleUpdateDto, options?: any): AxiosPromise<VoloAbpIdentityIdentityRoleDto> {
            return RoleApiFp(configuration).apiIdentityRolesIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VoloAbpIdentityIdentityRoleCreateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIdentityRolesPost(body?: VoloAbpIdentityIdentityRoleCreateDto, options?: any): AxiosPromise<VoloAbpIdentityIdentityRoleDto> {
            return RoleApiFp(configuration).apiIdentityRolesPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesAllGet(options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesAllGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [filter] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesGet(filter?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesGet(filter, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesIdDelete(id: string, options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesIdGet(id: string, options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {VoloAbpIdentityIdentityRoleUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesIdPut(id: string, body?: VoloAbpIdentityIdentityRoleUpdateDto, options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {VoloAbpIdentityIdentityRoleCreateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiIdentityRolesPost(body?: VoloAbpIdentityIdentityRoleCreateDto, options?: any) {
        return RoleApiFp(this.configuration).apiIdentityRolesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
