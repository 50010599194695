import TextUtil from '@/utils/text'
import NumberUtil from '@/utils/number'

export default class ValidateUtil {
  static isExternal(path: string) {
    return /^(https?:|mailto:|tel:)/.test(path)
  }

  static isEmail(email: string) {
    return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(email)
  }

  static isUUID(id: string) {
    return /^[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}/.test(id)
  }

  static validateEmail = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('请输入邮箱地址'))
    } else if (!ValidateUtil.isEmail(value)) {
      callback(new Error('请输入正确的邮箱地址'))
    } else {
      callback()
    }
  }

  static validateMobile = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('请输入手机号'))
    } else if (TextUtil.filterString(value).length !== 11) {
      callback(new Error('手机号长度应为11个字符'))
    } else if (NumberUtil.parseInt(value) === 0) {
      callback(new Error('手机号只能输入数字'))
    } else {
      callback()
    }
  }

  static validateQQ = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('请输入QQ号'))
    } else if (NumberUtil.parseInt(value) === 0) {
      callback(new Error('QQ号只能输入数字'))
    } else {
      callback()
    }
  }

  static validateWechat = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('请输入微信号'))
    } else {
      callback()
    }
  }

  static validateWecom = (rule: any, value: string, callback: Function) => {
    if (TextUtil.isEmpty(value)) {
      callback(new Error('请输入微信号'))
    } else {
      callback()
    }
  }
}
