/**
 * @Author: siyukok
 * @Date: 2022/2/25 2:52 PM
 * @Version 1.0
 */
import request from '@/utils/request'
import ApiConst from '@/constant/api'
import TextUtil from '@/utils/text'
import { IContactData, IFilterData, IPaymentRecord, IRefundRecord } from '@/constant/models'
import DateUtil from '@/utils/date'

export default class MarketingApi {
  static productList = (keyword = '') =>
    request({
      url: ApiConst.PRODUCT_SEARCH,
      method: 'get',
      params: {
        Name: keyword
      }
    })

  static createOrder(
    clueId: string,
    customerId: string,
    customerName: string,
    contactList: IContactData[] = [],
    productId: string,
    price: number,
    smartContractId: string,
    smartContractModelCode: string,
    {
      clueChannelId = '',
      clueChannelCommission = 0,
      activityId = '',
      activityChannelId = '',
      activityChannelCommission = 0,
      orderRemark = '',
      isClueStatusOrderSucceeded = false
    }) {
    const contactWayDictIds: string[] = contactList.map(contact => contact.dictId)
    const contactWays: string[] = contactList.map(contact => contact.value)
    return request({
      url: ApiConst.ORDER,
      method: 'post',
      data: {
        clueId,
        customerId,
        customerName,
        contactWayDictIds,
        contactWays,
        productId,
        price,
        smartContractId,
        smartContractModelCode,
        clueChannelId: TextUtil.translateEmptyToUndef(clueChannelId),
        clueChannelCommission,
        activityId: TextUtil.translateEmptyToUndef(activityId),
        activityChannelId: TextUtil.translateEmptyToUndef(activityChannelId),
        activityChannelCommission,
        orderRemark,
        isClueStatusOrderSucceeded
      }
    })
  }

  static orderList({
    customerId = '',
    sorting = '',
    page = 1,
    pageSize = 20
  }, filter?: IFilterData, productId?: string) {
    const params = {
      CustomerId: customerId,
      Sorting: sorting,
      SkipCount: (page - 1) * pageSize,
      MaxResultCount: pageSize,
      DateTimeType: filter?.dateRangeType,
      Filter: filter?.filter
    }
    filter?.orderStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `OrderStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    filter?.contractStatusTypes?.forEach((type, index) => {
      Object.defineProperty(params, `ContractStatus[${index}]`, {
        value: type,
        enumerable: true
      })
    })
    if (filter?.start) {
      Object.defineProperty(params, 'Start', {
        value: DateUtil.dateFormat(filter.start),
        enumerable: true
      })
    }
    if (productId) {
      Object.defineProperty(params, 'productIds', {
        value: [productId],
        enumerable: true
      })
    }
    if (filter?.end) {
      Object.defineProperty(params, 'End', {
        value: DateUtil.dateFormat(filter.end),
        enumerable: true
      })
    }
    return request({
      url: ApiConst.ORDER,
      method: 'get',
      params: params
    })
  }

  static customerOrders = ({
    OrderStatus = '',
    CustomerId = ''
  }) =>
    request({
      url: ApiConst.ORDER_CUSTOMER,
      method: 'get',
      params: {
        OrderStatus,
        CustomerId
      }
    })

  static orderDetail = (orderId: string) =>
    request({
      url: ApiConst.ORDER_DETAIL(orderId),
      method: 'get'
    })

  static orderSearch = (orderNo: string) =>
    request({
      url: ApiConst.ORDER_SEARCH,
      method: 'get',
      params: {
        orderNo
      }
    })

  static orderPayRecord = (orderId: string) =>
    request({
      url: ApiConst.ORDER_PAY_RECORD(orderId),
      method: 'get'
    })

  static addPaymentRecord = (orderId: string, record: IPaymentRecord) =>
    request({
      url: ApiConst.ORDER_PAYMENT_RECORD(orderId),
      method: 'post',
      data: record
    })

  static addRefundRecord = (orderId: string, record: IRefundRecord) =>
    request({
      url: ApiConst.ORDER_REFUND_RECORD(orderId),
      method: 'post',
      data: record
    })
}
