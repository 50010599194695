/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'data': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#606266" fill-rule="nonzero"><path pid="0" d="M9 4h2v10H9zM5 9h2v5H5zM13 7h2v7h-2zM3 15h14v1.5H3z"/></g>'
  }
})
